import { styled, useTheme } from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { TableV1 } from '../../../components/Table/TableV1';
import { useHolidayTableColumns } from '../hooks/useHolidayTableColumns';
import { HolidayBulkActions } from './HolidayBulkActions';
import { useHolidays } from '../../../queries/hooks/useHolidays';
import {
  holidaySelectedYear,
  holidayTable,
  removeHolidayModalOpen,
  removeHolidayModalSelected,
} from '../../../state/UIHolidayScheduleState';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';

export const ListViewTab = () => {
  const { colors } = useTheme();
  const [bulkModalOpen, setBulkModalOpen] = useAtom(removeHolidayModalOpen);
  const setBulkHolidaySelected = useSetAtom(removeHolidayModalSelected);
  const setHolidayTable = useSetAtom(holidayTable);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const selectedYear = useAtomValue(holidaySelectedYear);

  const { data: holidays, isLoading: isLoadingHolidays } = useHolidays(selectedYear);

  const columns = useHolidayTableColumns();

  const table = useReactTable({
    data: holidays ?? [],
    columns,
    state: {
      rowSelection,
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (holiday) => String(holiday.multidayId),
  });

  useEffect(() => {
    setHolidayTable(table);
  }, [table, setHolidayTable]);

  const handleBulkHolidayModal = () => {
    const selectedHolidays = table.getSelectedRowModel().rows.map((row) => row.original);
    setBulkModalOpen(!bulkModalOpen);
    setBulkHolidaySelected(selectedHolidays);
  };

  const hasSelectedItems = !!Object.keys(rowSelection)?.length;

  if (isLoadingHolidays) return <TableSkeletonLoader showFilterSkeleton={false} />;

  return (
    <Wrapper>
      {hasSelectedItems && (
        <HolidayBulkActions table={table} handleBulkHolidayModal={handleBulkHolidayModal} />
      )}
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')``;
