import { Box, Button, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { MonthlyTimesheet, TimesheetStatus } from '../../../types';
import { getProxyTsStatusLabel } from '../../../utils/tsMappers';

type Props = {
  timesheet?: MonthlyTimesheet;
};

export function ProxyStatusCell({ timesheet }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const statusLabel = useMemo(() => {
    return getProxyTsStatusLabel(timesheet?.status as TimesheetStatus);
  }, [timesheet?.status]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');

  const redirectToSingleTs = () => {
    const queryParam = yearQueryParam ? `?year=${yearQueryParam}` : '';
    navigate(`/${ROUTES.PROXY_TIMESHEETS_SUMMARY}/${timesheet?.id}${queryParam}`);
  };

  if (!timesheet) return <></>;

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
      <Button
        variant='text'
        style={{
          background: colors.surfaceStatus.active,
          color: colors.textStatus.active,
          border: `1px solid ${colors.border.default}`,
          width: '100%',
          height: '90%',
          borderRadius: '4px',
        }}
        onClick={redirectToSingleTs}
      >
        {statusLabel}
      </Button>
    </Box>
  );
}
