import { Button, IconButton, styled, useTheme, Modal } from '@mui/material';
import { FC } from 'react';
import { Typography } from '../Typography/Typography';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-gray.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-blue.svg';
import { isSmallScreenState } from '../../state/UIState';
import { useAtomValue } from 'jotai';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  note?: string;
  title: string;
}

const Wrapper = styled('div')<{isSmallScreen: boolean}>`
  width: ${({isSmallScreen}) => isSmallScreen ? '90%' : '564px'};
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  border-radius: 4px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')<{isSmallScreen: boolean}>`
  padding: ${({isSmallScreen}) => isSmallScreen ? '12px' : '16px 32px'};
`;
const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
`;
const ButtonsWrapper = styled('div')<{isSmallScreen: boolean}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: ${({isSmallScreen}) => isSmallScreen ? '12px' : '16px 32px'};
`;

const InfoWrapper = styled('div')`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
  svg {
    overflow: visible;
    margin-top: 2px;
  }
`;

export const ConfirmModal: FC<Props> = ({
  open,
  onClose,
  cancelLabel,
  confirmLabel,
  onConfirm,
  note,
  title,
}) => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <Modal open={open}>
      <Wrapper isSmallScreen={isSmallScreen}>
        <Content isSmallScreen={isSmallScreen}>
          <TitleWrapper>
            <Typography variant='h5' color={colors.text.main}>
              {title}
            </Typography>
            <IconButton onClick={onClose} style={{ marginRight: '-10px' }}>
              <CloseIcon />
            </IconButton>
          </TitleWrapper>
          {note && (
            <InfoWrapper>
              <InfoIcon />
              <Typography variant='body' color={colors.textAccent.default}>
                {note}
              </Typography>
            </InfoWrapper>
          )}
        </Content>
        <ButtonsWrapper isSmallScreen={isSmallScreen}>
          <Button variant='outlined' onClick={onClose}>
            {cancelLabel || 'Cancel'}
          </Button>
          <Button variant='contained' onClick={onConfirm}>
            {confirmLabel || 'Confirm'}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};
