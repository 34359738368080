import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AccontantOpOverviewTable } from './components/AccontantOpOverviewTable';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

export function AccountantOpOverview() {
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='Operating partners overview'
        description=''
        breadcrumbs={[{ title: 'Operating partners overview' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <AccontantOpOverviewTable />
      </ContentWrapper>
    </>
  );
}
