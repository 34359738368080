import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useOPsTableColumns } from '../hooks/useOPsTableColumns';
import { useCallback, useMemo, useState } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { TableV1 } from '../../../components/Table/TableV1';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SelectItem, USER_COUNTRY, UserRole } from '../../../types';
import { Typography } from '../../../components/Typography/Typography';
import { useUsers } from '../../../queries/hooks/useUsers';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';

const countryList = [
  { id: 1, value: USER_COUNTRY.US },
  { id: 2, value: USER_COUNTRY.UK },
];

export const OPTable = () => {
  const { isLoading: areUsersLoading, data: users } = useUsers();
  const { colors } = useTheme();
  const columns = useOPsTableColumns();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedCountry, setSelectedCountry] = useState<SelectItem[]>([]);

  const allOPs = useMemo(() => {
    return users?.filter((user) => user.role === UserRole.OPERATING_PARTNER || user.isOp);
  }, [users]);

  const table = useReactTable({
    data: allOPs ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (user) => String(user.id),
  });

  const onChangeCountry = useCallback(
    (countries: SelectItem[] | undefined) => {
      if (countries) {
        setSelectedCountry(countries);
        table?.getColumn('country')?.setFilterValue(countries);
      }
    },
    [table]
  );

  const isResetDisabled = useMemo(() => {
    const filterValues = columnFilters.map((filter) => filter.value);
    if (filterValues.every((val: any) => !val || val.length === 0)) return true;
    return false;
  }, [columnFilters]);

  const onFiltersReset = useCallback(() => {
    table.getColumn('name')?.setFilterValue('');
    table.getColumn('country')?.setFilterValue([]);
    setSelectedCountry([]);
  }, [table, setSelectedCountry]);

  if (areUsersLoading || !users) return <TableSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersSection>
        <SearchInput
          placeholder='Search'
          style={{ width: isSmallScreen ? '150px' : '370px', height: '36px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <Multiselect
          style={{ width: isSmallScreen ? '150px' : '200px', marginRight: '14px' }}
          fieldPlaceholder={isSmallScreen ? 'Country' : 'Select Country'}
          value={selectedCountry}
          onChange={(_, countries) => onChangeCountry(countries)}
          options={countryList}
        />
        <Button
          onClick={onFiltersReset}
          variant='text'
          style={{ height: '28px', marginTop: '2px' }}
          disabled={isResetDisabled}
          sx={{
            '.MuiButton-startIcon': { marginRight: '2px' },
            '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          }}
        >
          <Typography variant='subtitle1' color={colors.textAccent.default}>
            Reset Filters
          </Typography>
        </Button>
      </FiltersSection>
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')``;

const FiltersSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;
