import {
  ButtonBase,
  IconButton,
  Menu,
  MenuItem as MuiMenuItem,
  styled,
  useTheme,
} from '@mui/material';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as Help } from '../../assets/icons/help-question.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as ThemeIcon } from '../../assets/icons/contrast.svg';
import { ReactComponent as DarkThemeSvg } from '../../assets/icons/dark-theme.svg';
import { ReactComponent as LightThemeSvg } from '../../assets/icons/light-theme.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-icon-default.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/pin-icon.svg';
import { ReactComponent as PinnedIcon } from '../../assets/icons/pinned-icon.svg';
import { ROUTES } from '../../constants/routes';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../constants/layoutSizes';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectedThemeState, userState } from '../../state/UIState';
import { MainMenuItemProps, THEME, UserRole } from '../../types';
import { useIcons } from '../../hooks/useIcons';
import { Typography } from '../../components/Typography/Typography';
import { useOktaAuth } from '@okta/okta-react';
import { motion, useAnimation } from 'framer-motion';
import { MenuItem } from './MenuItem';
import { showOnboardingState } from '../../state/UITimesheetsState';
import { getMenuItems } from './hooks/menuHelper';

const Wrapper = styled(motion.div)`
  position: absolute;
  height: 100vh;
  border-right: 1px solid ${({ theme }) => theme.colors.border.default};
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  display: flex;
  flex-direction: column;
  z-index: 99;
  overflow: hidden;
  justify-content: space-between;
`;

const LogoWrapper = styled('div')<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '220px' : '64px')};
  padding-left: 8px;
`;

const AvatarWrapper = styled('div')`
  margin: 30px 0;
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const MainMenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 8px;
`;

const IconsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ThemeOptionLabel = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export interface ButtonProps {
  isActive?: boolean;
  disableHover?: boolean;
  isExpanded?: boolean;
}

const IconWrapper = styled('div')<ButtonProps>`
  height: 48px;
  border-radius: 4px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.surfaceInteraction.selectedLight : 'transparent'};
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  gap: 12px;
`;

interface Props {
  onPinToggle: () => void;
  isPinned: boolean;
}

export const SidebarMenu: FC<Props> = ({ isPinned, onPinToggle }) => {
  const controls = useAnimation();
  const { oktaAuth } = useOktaAuth();
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const [selectedTheme, setSelectedTheme] = useAtom(selectedThemeState);
  const { LogoutIcon } = useIcons();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isUserMenuOpen = Boolean(userMenuAnchorEl);
  const [themeMenuAnchorEl, setThemeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isThemeMenuOpen = Boolean(themeMenuAnchorEl);
  const [isExpanded, setIsExpanded] = useState(false);
  const setShowOnboarding = useSetAtom(showOnboardingState);

  const onHoverStart = () => {
    if (isPinned) return controls.stop();
    setIsExpanded(true);
    controls.start({
      width: `${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`,
      transition: { duration: 0.3 },
    });
  };

  const onHoverEnd = () => {
    if (isPinned) return controls.stop();
    setIsExpanded(false);
    controls.start({ width: `${MAIN_SIDEBAR_MENU_WIDTH}px`, transition: { duration: 0.3 } });
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleOpenUserManu = useCallback((event: MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseThemeMenu = () => {
    setThemeMenuAnchorEl(null);
  };

  const handleOpenThemeManu = useCallback((event: MouseEvent<HTMLElement>) => {
    setThemeMenuAnchorEl(event.currentTarget);
  }, []);

  const userMenuOptions = useMemo(() => {
    return [
      {
        id: 'PROFILE',
        value: 'Profile Information',
        icon: <UserIcon />,
      },
      {
        id: 'THEME',
        value: 'Theme',
        icon: <ThemeIcon />,
      },
      {
        id: 'LOGOUT',
        value: 'Log Out',
        icon: <LogoutIcon />,
      },
    ];
  }, [LogoutIcon]);

  const handleSelectUserMenuOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: string) => {
      e.stopPropagation();

      switch (id) {
        case 'PROFILE':
          console.log('Profile Information');
          return;
        case 'THEME':
          handleOpenThemeManu(e);
          return;
        case 'LOGOUT':
          oktaAuth.signOut();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [handleOpenThemeManu, oktaAuth]
  );

  const themeOptions = useMemo(() => {
    return [
      {
        id: THEME.LIGHT,
        value: 'Light Theme',
        icon: <LightThemeSvg />,
      },
      {
        id: THEME.DARK,
        value: 'Dark Theme',
        icon: <DarkThemeSvg />,
      },
    ];
  }, []);

  const handleSelectThemeOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: THEME) => {
      localStorage.setItem('theme', id);
      setSelectedTheme(id);
    },
    [setSelectedTheme]
  );

  const helpItem = useMemo<MainMenuItemProps>(() => {
    return {
      icon: <Help />,
      route: `/${ROUTES.PENDING_TIMESHEETS}`,
      id: ROUTES.PENDING_TIMESHEETS,
      title: 'Help',
      show: true,
      children: [],
    };
  }, []);

  return (
    <Wrapper
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      animate={controls}
      style={{
        width: isPinned ? `${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px` : `${MAIN_SIDEBAR_MENU_WIDTH}px`,
      }}
    >
      <MainMenuWrapper>
        <LogoWrapper
          isExpanded={isExpanded}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <ButtonBase
            TouchRippleProps={{ color: 'white' }}
            style={{ borderRadius: '4px' }}
            sx={{
              svg: { path: { fill: colors.icon.accent } },
            }}
          >
            <IconWrapper disableHover style={{ paddingLeft: '4px' }}>
              <Logo />
            </IconWrapper>
          </ButtonBase>
          {isExpanded && (
            <IconButton
              onClick={onPinToggle}
              style={{ width: '36px', height: '36px' }}
              sx={{
                svg: { path: { fill: isPinned ? colors.icon.accent : colors.icon.disabled } },
              }}
            >
              {isPinned ? <PinnedIcon /> : <PinIcon />}
            </IconButton>
          )}
        </LogoWrapper>
        {user && user.role && (
          <IconsWrapper>
            {getMenuItems(user).map((item) => (
              <MenuItem key={item.route} item={item} isExpanded={isExpanded} />
            ))}
          </IconsWrapper>
        )}
      </MainMenuWrapper>
      <div>
        {user && (user.role === UserRole.OPERATING_PARTNER || user.isOp) && (
          <IconsWrapper
            onClick={() => {
              setShowOnboarding(true);
              localStorage.removeItem('hasCompletedOnboarding');
            }}
          >
            <MenuItem item={helpItem} isExpanded={isExpanded} />
          </IconsWrapper>
        )}
        <AvatarWrapper
          onClick={(e) => {
            handleOpenUserManu(e);
          }}
        >
          <UserAvatar name={user?.name ?? ''} style={{ minWidth: '40px' }} />
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0 }}
            >
              <Typography
                variant='subtitle1'
                color={colors.text.main}
                style={{ marginBottom: '-2px' }}
              >
                Profile
              </Typography>
            </motion.div>
          )}
          <Menu
            anchorEl={userMenuAnchorEl}
            open={isUserMenuOpen}
            onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleCloseUserMenu();
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            MenuListProps={{ style: { padding: 0, border: 'none' } }}
            PaperProps={{
              style: {
                width: '290px',
                transform: 'translateX(58px)',
                backgroundColor: colors.surfaceBackground.bg1,
              },
            }}
          >
            <MuiMenuItem
              style={{
                height: '72px',
                gap: '12px',
                borderBottom: `1px solid ${colors.border.default}`,
                backgroundColor: 'transparent',
              }}
              //remove hover bg color
              sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'auto' } }}
              disableRipple
            >
              <UserAvatar name={user?.name ?? ''} style={{ cursor: 'auto' }} />
              <div>
                <Typography variant='h4' color={colors.text.main}>
                  {user?.name ?? ''}
                </Typography>
                <Typography variant='caption' color={colors.text.caption}>
                  {user?.email ?? ''}
                </Typography>
              </div>
            </MuiMenuItem>
            {userMenuOptions.map((option) => (
              <MuiMenuItem
                key={option.id}
                onClick={(e) => handleSelectUserMenuOption(e, option.id)}
                style={{
                  width: '100%',
                  height: '40px',
                  borderBottom:
                    option.id === 'THEME' ? `1px solid ${colors.border.default}` : 'none',
                }}
              >
                {option.icon}
                <Typography
                  variant='body'
                  color={option.id === 'LOGOUT' ? colors.textAccent.default : colors.text.main}
                  style={{ marginLeft: '4px', marginBottom: '-2px' }}
                >
                  {option.value}
                </Typography>
              </MuiMenuItem>
            ))}
          </Menu>
          <Menu
            anchorEl={themeMenuAnchorEl}
            open={isThemeMenuOpen}
            onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleCloseThemeMenu();
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            MenuListProps={{
              style: { padding: 0, border: 'none' },
              onMouseLeave: handleCloseThemeMenu,
            }}
            PaperProps={{
              style: {
                width: '240px',
                transform: 'translateX(350px) translateY(-40px)',
                backgroundColor: colors.surfaceBackground.bg1,
              },
            }}
          >
            {themeOptions.map((option) => (
              <MuiMenuItem
                key={option.id}
                onClick={(e) => handleSelectThemeOption(e, option.id)}
                style={{ width: '100%', height: '52px', justifyContent: 'space-between' }}
              >
                <ThemeOptionLabel>
                  {option.icon}
                  <Typography
                    variant='body'
                    color={colors.text.main}
                    style={{ marginLeft: '4px', marginBottom: '-2px' }}
                  >
                    {option.value}
                  </Typography>
                </ThemeOptionLabel>
                {option.id === selectedTheme && <CheckIcon />}
              </MuiMenuItem>
            ))}
          </Menu>
        </AvatarWrapper>
      </div>
    </Wrapper>
  );
};
