import { PageHeader } from '../layout/PageHeader';
import { useTheme } from '@mui/material';
import { ROUTES } from '../../constants/routes';
import { ContentWrapper } from '../layout/styled';
import { PendingTimesheeetsTable } from './components/PendingTimesheeetsTable';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

function DealOwnerPendingTimesheets() {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='Pending Timesheets'
        description=''
        breadcrumbs={[
          {
            title: 'All timesheet',
            url: `/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`,
            color: colors.textAccent.default,
          },
          {
            title: 'Pending Timesheets',
          },
        ]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <PendingTimesheeetsTable />
      </ContentWrapper>
    </>
  );
}

export default DealOwnerPendingTimesheets;
