import { useAtom } from 'jotai';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { HolidayFormContent } from './HolidayFormContent';
import {
  SelectItem,
  Holiday,
  LoadingId,
  HolidayTypeItems,
} from '../../../types';
import { useAddHoliday } from '../../../queries/hooks/useAddHoliday';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
  addHolidayModalSelected,
  addHolidayModalOpen,
  addHolidayModalSelectedStart,
} from '../../../state/UIHolidayScheduleState';
import { useEditHoliday } from '../../../queries/hooks/useEditHoliday';

export interface IAddModalFields {
  name: string;
  startDate: string | null;
  isMultiday: boolean;
  endDate: string | null;
  isUSHoliday: boolean;
  isUKHoliday: boolean;
  type: SelectItem | null;
}

const defaultInitialValues = {
  name: '',
  startDate: null,
  isMultiday: false,
  endDate: null,
  isUSHoliday: true,
  isUKHoliday: false,
  type: null,
};

export function HolidayFormModal() {
  const [holiday, setHoliday] = useAtom(addHolidayModalSelected);
  const [holidayModalOpen, setHolidayModalOpen] = useAtom(addHolidayModalOpen);
  const [holidayModalStart, setHolidayModalStart] = useAtom(addHolidayModalSelectedStart);
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { stopLoading } = useLoadingBar();

  const { mutate: onAddHoliday, isLoading: addHolidayInProgress } = useAddHoliday();
  const { mutate: onEditHoliday, isLoading: editHolidayInProgress } = useEditHoliday();

  const submitInProgress = addHolidayInProgress || editHolidayInProgress;

  const handleSubmitSuccess = (newHoliday: Holiday[]) => {
    pushSuccessToast({
      title: toastSuccesTitle,
      message: `${newHoliday[0]?.name} was ${holiday ? 'updated' : 'added'}.`,
    });
    handleHolidayModalClose();
  };
  const handleSubmitError = () => {
    pushErrorToast({ message: `Failed to ${holiday ? 'update' : 'add'} event` });
  };
  const handleSubmitSettled = () => {
    stopLoading(LoadingId.addHoliday);
  };

  const handleHolidayModalClose = () => {
    setHoliday(null);
    setHolidayModalStart('');
    setHolidayModalOpen(false);
  };

  const initialValues = useMemo(() => {
    if (holiday) {
      return {
        name: holiday.name,
        startDate: holiday.startDate,
        endDate: holiday.endDate,
        isMultiday: holiday.isMultiday,
        isUSHoliday: holiday.country.includes('US'),
        isUKHoliday: holiday.country.includes('UK'),
        type: HolidayTypeItems.find((type) => type.value === holiday.type) as SelectItem,
      };
    }
    if (holidayModalStart) {
      return {
        name: '',
        startDate: holidayModalStart,
        endDate: '',
        isMultiday: false,
        isUSHoliday: true,
        isUKHoliday: false,
        type: null,
      };
    }
    return defaultInitialValues;
  }, [holiday, holidayModalStart]);

  const title = holiday ? `Edit ${holiday.name}` : 'Add Event';
  const toastSuccesTitle = holiday ? 'Event Updated' : 'Event Added';

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={initialValues}
      validateOnMount
      validateOnChange
      onSubmit={(values: IAddModalFields) => {
        const country = { UK: values.isUKHoliday, US: values.isUSHoliday };

        const payload = {
          type: values?.type?.value ?? '',
          name: values?.name,
          country: Object.entries(country)
            .filter(([_, value]) => value)
            .map(([key]) => key)
            .join(','),
          startDate: values.startDate!,
          endDate: values.endDate ?? '',
        };

        if (holiday) {
          onEditHoliday(
            {
              payload,
              multidayId: holiday.multidayId,
            },
            {
              onSuccess: handleSubmitSuccess,
              onError: handleSubmitError,
              onSettled: handleSubmitSettled,
            }
          );
          return;
        }

        onAddHoliday(payload, {
          onSuccess: handleSubmitSuccess,
          onError: handleSubmitError,
          onSettled: handleSubmitSettled,
        });
      }}
    >
      {({ handleSubmit, values }) => {
        const hasValidationErrors =
          !values.name ||
          !values.startDate ||
          !values.type ||
          (!values.isUKHoliday && !values.isUSHoliday);
        return (
          <Form>
            <ModalComponent
              isOpen={holidayModalOpen}
              onClose={handleHolidayModalClose}
              actionButtonsProps={{
                isSubmitDisabled: submitInProgress || hasValidationErrors,
                onSubmit: handleSubmit,
                onCancel: handleHolidayModalClose,
                submitText: holiday ? 'Update Event' : 'Add Event',
              }}
              title={title}
              top='80px'
            >
              <HolidayFormContent />
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
}
