import React from 'react';
import { PageHeader } from '../layout/PageHeader';
import { useTheme } from '@mui/material';
import { ContentWrapper } from '../layout/styled';
import { ROUTES } from '../../constants/routes';
import { DealOwnerDealsTable } from './components/DealOwnerDealsTable';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

function DealOwnerDeals() {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='All Deals'
        description=''
        breadcrumbs={[
          {
            title: 'All timesheet',
            url: `/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`,
            color: colors.textAccent.default,
          },
          {
            title: 'Deal onwer deals',
          },
        ]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <DealOwnerDealsTable />
      </ContentWrapper>
    </>
  );
}

export default DealOwnerDeals;
