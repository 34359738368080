import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { MonthlyTimesheet, TimesheetStatusLabel } from '../../../types';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { Button, Chip, useTheme } from '@mui/material';
import { getMonthLabelShort } from '../../../utils/formatters';
import { useNavigate } from 'react-router';

const columnHelper = createColumnHelper<MonthlyTimesheet>();

type Props = {
  status: TimesheetStatusLabel;
  timesheetId: number;
};

function StatusCell({ status, timesheetId }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <Button onClick={() => {
      navigate(`${timesheetId}`)
    }}>
      <Chip
        style={{
          backgroundColor: colors.surfaceStatus.noAction,
          minWidth: '66px',
          height: '28px',
          border: `1px solid ${colors.border.default}`,
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        label={
          <Typography variant='body' color={colors.textAccent.default}>
            {status}
          </Typography>
        }
      />
    </Button>
  );
}

export const useOPTsStatusesColumns = () => {
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const { colors } = useTheme();
  const columns = useMemo(
    () => [
      columnHelper.accessor('month', {
        cell: (info) => (
          <Typography variant='body' color={colors.text.main}>
            {getMonthLabelShort(info.getValue())} {info.row.original.year}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Month' />,
        meta: {
          width: isSmallScreen ? '150px' : '350px',
          minWidth: isSmallScreen ? '150px' : '350px',
          maxWidth: isSmallScreen ? '150px' : '350px',
          thStyles: { paddingLeft: '16px', borderRight: 'none' },
          tdStyles: { paddingLeft: '16px', borderRight: 'none' },
        },
      }),
      columnHelper.accessor('status', {
        cell: (info) => <StatusCell status={info.getValue() as TimesheetStatusLabel} timesheetId={info.row.original.id}/>,
        header: () => <TableHeaderCell text='TS Status' />,
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
    ],
    [colors.text.main, isSmallScreen]
  );

  return columns;
};
