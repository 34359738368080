import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { WeekdayTimesheetTableData } from '../../../types';
import TotalCellWeekdayTable from '../components/TotalCellWeekdayTable';
import { formatDecimalNumber } from '../../../utils/formatters';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';

const columnHelper = createColumnHelper<WeekdayTimesheetTableData>();

const metaCellStyle = {
  width: '16%',
  maxWidth: '16%',
  minWidth: '16%',
  tdStyles: { paddingLeft: '16px', borderRight: `none` },
  thStyles: {
    borderRight: `none`,
  },
};

export const useAccountantSingleTableColumns = () => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          const isFixedDeal = info.row.original.dealId === 0;
          return (
            <AvatarCell
              name={info.row.original.name}
              avatarBoxStyle={{
                background: isFixedDeal
                  ? colors.surfaceBackground.highlighted
                  : colors.surfaceIndicator.needsReview,
              }}
              avatarLetterStyle={{
                color: colors.text.main,
              }}
            />
          );
        },
        enableSorting: false,
        header: () => <TableHeaderCell text='Deal' />,
        footer: () => {
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                paddingLeft: '30px',
                fontSize: '17px',
              }}
            >
              Total per day
            </Typography>
          );
        },
        meta: {
          width: isSmallScreen ? '180px' : 'auto',
          maxWidth: isSmallScreen ? '180px' : 'auto',
          minWidth: isSmallScreen ? '180px' : 'auto',
          tdStyles: { paddingLeft: isSmallScreen ? '8px' : '16px', borderRight: `none` },
          thStyles: { paddingLeft: isSmallScreen ? '8px' : '16px', borderRight: `none` },
        },
      }),
      columnHelper.accessor('monday', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {formatDecimalNumber(info.getValue(), 2) + '%'}
          </Typography>
        ),
        enableSorting: false,
        header: () => <TableHeaderCell text='Monday' />,
        footer: TotalCellWeekdayTable,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('tuesday', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {formatDecimalNumber(info.getValue(), 2) + '%'}
          </Typography>
        ),
        enableSorting: false,
        header: () => <TableHeaderCell text='Tuesday' />,
        footer: TotalCellWeekdayTable,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('wednesday', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {formatDecimalNumber(info.getValue(), 2) + '%'}
          </Typography>
        ),
        enableSorting: false,
        header: () => <TableHeaderCell text='Wednesday' />,
        footer: TotalCellWeekdayTable,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('thursday', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {formatDecimalNumber(info.getValue(), 2) + '%'}
          </Typography>
        ),
        enableSorting: false,
        header: () => <TableHeaderCell text='Thursday' />,
        footer: TotalCellWeekdayTable,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('friday', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {formatDecimalNumber(info.getValue(), 2) + '%'}
          </Typography>
        ),
        enableSorting: false,
        header: () => <TableHeaderCell text='Friday' />,
        footer: TotalCellWeekdayTable,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('avg', {
        cell: (info) => (
          <Typography
            variant='body'
            color={colors.text.main}
            style={{
              textWrap: 'wrap',
            }}
          >
            {formatDecimalNumber(info.getValue(), 2) + '%'}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Total per deal' />,
        footer: TotalCellWeekdayTable,
        enableSorting: false,
        meta: {
          width: '20%',
          maxWidth: '20%',
          minWidth: '20%',
          tdStyles: { paddingLeft: isSmallScreen ? '8px' : '16px', paddingRight: isSmallScreen ? '8px' : '16px' },
          thStyles: { borderRight: `none`, paddingLeft: isSmallScreen ? '8px' : '16px' },
        },
      }),
    ],
    [colors.surfaceBackground.highlighted, colors.surfaceIndicator.needsReview, colors.text.main, isSmallScreen]
  );

  return columns;
};
