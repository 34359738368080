import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { AvailableMonthlyTimesheet } from '../../types';
import { QUERY_KEYS } from '../constants';

async function fetchAllMonthlyTimesheets(
  userIds: string
): Promise<AvailableMonthlyTimesheet[]> {
  const { data } = await Http.axios.get<AvailableMonthlyTimesheet[]>(
    `/monthly-timesheet/time-range/all?ids=${userIds}`
  );
  return data;
}

export const useAllMonthlyTimesheets = (userIds: string) => {
  return useQuery(
    [QUERY_KEYS.ALL_MONTHLY_TIMESHEET_TO_EXPORT, userIds],
    () => fetchAllMonthlyTimesheets(userIds),
    {
      staleTime: Infinity, 
      enabled: !!userIds,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useInvalidateAllMonthlyTimesheets = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.ALL_MONTHLY_TIMESHEET_TO_EXPORT,
    });
  };
};
