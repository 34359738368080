/* istanbul ignore file */
import { ThemeOptions, createTheme } from '@mui/material';
import { colors } from './color-tokens';
import { text } from '../text';

export const darkTheme: ThemeOptions = createTheme({
  text,
  typography: {
    fontFamily: 'BasierSquare-Regular',
    fontWeightBold: 'BasierSquare-Medium',
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          'background-color': colors.skeletonLoader.linearProgressBackground,
          '& .MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Indeterminate.MuiLinearProgress-bar':
            {
              backgroundColor: colors.skeletonLoader.linearProgressForeground,
            },
          '& .MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-bar':
            {
              backgroundColor: colors.skeletonLoader.linearProgressForeground,
            },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-thumb': {
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 2px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          },
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.surfaceAccent.default,
          },
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: colors.surfaceAccent.default,
          },
          '& .MuiSwitch-track': {
            backgroundColor: colors.border.accent,
            opacity: 1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontFamily: text.font.basierMedium,
          fontWeight: text.weight.medium,
          fontSize: text.size.mediumSmall,
          lineHeight: text.lineHeight.small,
          height: '36px',
          textTransform: 'none',
          '&.MuiButton-outlined': {
            color: colors.textAccent.default,
            border: `1px solid ${colors.border.default}`,
            backgroundColor: colors.surfaceBackground.bg1,
          },
          '&.MuiButton-outlined:hover': {
            border: `1px solid ${colors.border.hover}`,
            backgroundColor: colors.surfaceInteraction.hover,
          },
          '&.MuiButton-outlined.Mui-disabled': {
            border: `1px solid ${colors.border.default}`,
            backgroundColor: colors.surfaceInteraction.disabled,
            color: colors.text.caption,
          },
          '&.MuiButton-contained': {
            color: colors.icon.inverse,
            backgroundColor: colors.surfaceAccent.default,
          },
          '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: colors.border.default,
            color: colors.text.caption,
          },
          '&.MuiButton-contained:hover': {
            backgroundColor: colors.surfaceAccent.hover,
          },
          '&.MuiButton-text:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: text.font.basierRegular,
          fontWeight: text.weight.regular,
          fontSize: text.size.small,
          lineHeight: text.lineHeight.small,
          padding: '12px 12px',
          minHeight: '0',
          color: colors.textAccent.hover,
          '&&.mui-custom-tab.Mui-selected': {
            color: colors.textStatus.active,
            fontFamily: text.font.basierRegular,
            fontWeight: text.weight.medium,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            backgroundColor: colors.surfaceAccent.default,
            minHeight: '2px',
            height: '2px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.icon.defaultControls,
          '&.Mui-checked': {
            color: colors.icon.default,
          },
          '&.MuiCheckbox-indeterminate': {
            color: colors.icon.default,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.border.accent}`,
          },
          '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.border.accent}`,
          },
          '.MuiOutlinedInput-root.Mui-disabled': {
            borderColor: colors.border.default,
          },
          '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.border.default,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.border.default,
          },
          '.MuiInputBase-root': {
            height: '36px',
            minWidth: '215px',
            '@media (max-width: 900px)': {
              minWidth: '140px'
            }
          },
          '.MuiInputBase-root .MuiInputBase-input': {
            fontFamily: text.font.basierRegular,
            fontWeight: text.weight.regular,
            fontSize: text.size.mediumSmall,
            lineHeight: text.lineHeight.mediumSmall,
            color: colors.text.main,
          },
          '.MuiInputBase-root .MuiInputBase-input::placeholder': {
            color: colors.text.caption,
            opacity: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiSelect-select': {
            fontFamily: text.font.basierRegular,
            fontWeight: text.weight.regular,
            fontSize: text.size.mediumSmall,
            lineHeight: text.lineHeight.mediumSmall,
            color: colors.icon.default,
          },
          '.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
            width: '110px',
            height: '28px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            padding: '0 8px',
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.border.accent}`,
          },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.border.accent}`,
          },
          '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.border.default,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '284px',
          padding: '12px',
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          height: '32px',
          borderRadius: '4px',
          '&.Mui-selected.MuiMenuItem-root': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected.MuiMenuItem-root:hover': {
            backgroundColor: colors.surfaceInteraction.selectedLight,
          },
          '&:hover': {
            backgroundColor: colors.surfaceInteraction.selectedLight,
          },
          color: colors.text.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '0 8px',
          height: '26px',
          borderRadius: '4px',

          justifyContent: 'start',
          '.MuiChip-label': {
            padding: '0',
            fontSize: text.size.small,
            lineHeight: text.lineHeight.small,
            fontWeight: text.weight.regular,
            letterSpacing: '0px',
          },
          '.MuiChip-icon': {
            margin: '0 4px 0 0',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: colors.surfaceBackground.bg1,
        },
        noOptions: {
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled input::placeholder': {
            color: colors.text.caption,
            opacity: 1,
          },
        },
      },
    },
  },
  colors: colors,
});
