import { useAtom, useAtomValue } from 'jotai';
import { Box, Button, useTheme } from '@mui/material';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { ReactComponent as AddIcon } from '../../assets/icons/add-white.svg';
import { ReactComponent as ImportIcon } from '../../assets/icons/import-blue.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/export-blue.svg';
import { Typography } from '../../components/Typography/Typography';
import { HolidayFormModal } from './components/HolidayFormModal';
import { FilterSection } from './components/FilterSection';
import {
  addHolidayModalOpen,
  holidaySelectedTab,
  removeHolidayModalOpen,
} from '../../state/UIHolidayScheduleState';
import { HolidayTab } from '../../types';
import { CalendarViewTab } from './components/CalendarViewTab';
import { ListViewTab } from './components/ListViewTab';
import { RemoveHolidaysModal } from './components/RemoveHolidaysModal';
import { isSmallScreenState } from '../../state/UIState';
import { useState } from 'react';
import { ImportHolidaysModal } from './components/ImportHolidaysModal';
import { ExportHolidaysModal } from './components/ExportHolidaysModal';

export const HolidaySchedule = () => {
  const [selectedTab, setSelectedTab] = useAtom(holidaySelectedTab);
  const [holidayModalOpen, setHolidayModalOpen] = useAtom(addHolidayModalOpen);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const removeHolidayOpen = useAtomValue(removeHolidayModalOpen);
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const handleAddHolidayModal = () => {
    setHolidayModalOpen(!holidayModalOpen);
  };

  const handleTabChange = (tab: HolidayTab) => {
    setSelectedTab(tab);
  };

  const handleImportHolidayModal = () => {
    setImportModalOpen(true);
  };

  const handleExportHolidayModal = () => {
    setExportModalOpen(true);
  };

  return (
    <>
      <PageHeader
        title='Event Schedule'
        breadcrumbs={[{ title: 'Administration' }, { title: 'Event Schedule' }]}
        description="Set and track your team's events"
        actionComponent={
          <Box>
            <Button
              variant='outlined'
              startIcon={<ImportIcon />}
              onClick={handleImportHolidayModal}
              style={{
                backgroundColor: colors.surfaceBackground.bg1,
                height: '40px',
                marginRight: '10px',
              }}
            >
              <Typography
                variant='h4'
                color={colors.textAccent.default}
                style={{ marginBottom: '-1px' }}
              >
                Import
              </Typography>
            </Button>
            <Button
              variant='outlined'
              startIcon={<ExportIcon />}
              onClick={handleExportHolidayModal}
              style={{
                backgroundColor: colors.surfaceBackground.bg1,
                height: '40px',
                marginRight: '10px',
              }}
            >
              <Typography
                variant='h4'
                color={colors.textAccent.default}
                style={{ marginBottom: '-1px' }}
              >
                Export
              </Typography>
            </Button>
            <Button
              variant='contained'
              startIcon={<AddIcon />}
              style={{ height: '40px' }}
              onClick={handleAddHolidayModal}
            >
              <Typography
                variant='h4'
                color={colors.textAccent.inverse}
                style={{ marginBottom: '-1px' }}
              >
                Add Event
              </Typography>
            </Button>
          </Box>
        }
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <FilterSection selectedTab={selectedTab} handleTabChange={handleTabChange} />
        {selectedTab === HolidayTab.Calendar && <CalendarViewTab />}
        {selectedTab === HolidayTab.List && <ListViewTab />}
      </ContentWrapper>
      {holidayModalOpen && <HolidayFormModal />}
      {removeHolidayOpen && <RemoveHolidaysModal />}
      {importModalOpen && (
        <ImportHolidaysModal
          isOpen={importModalOpen}
          handleClose={() => setImportModalOpen(false)}
        />
      )}
      {exportModalOpen && (
        <ExportHolidaysModal
          isOpen={exportModalOpen}
          handleClose={() => setExportModalOpen(false)}
        />
      )}
    </>
  );
};
