import { IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/icons/chevron-right.svg';

import { Typography } from '../../../../components/Typography/Typography';
import { Holiday, TimesheetStatus, UserRole } from '../../../../types';

import { useAtomValue } from 'jotai';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../../../constants/routes';
import { useTimesheets } from '../../../../queries/hooks/useTimesheets';
import { userState } from '../../../../state/UIState';
import { formatTSWeekFormat } from '../../../../utils/formatters';
import { useMemo } from 'react';

type Props = {
  weekDates?: string[];
  OPId?: number;
  holidays?: Holiday[];
};

export function TimesheetNavigation({ weekDates, OPId, holidays }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const timesheetId = useParams().id;
  const location = useLocation();

  const isAllTimesheets = location.pathname.includes(ROUTES.ALL_TIMESHEETS);
  const isIssuedTimesheets = location.pathname.includes(ROUTES.ISSUED_TIMESHEETS);

  const currentTimesheetId = Number(timesheetId);

  const weekName = useMemo(() => {
    if (weekDates?.length) {
      return formatTSWeekFormat(weekDates[0], weekDates[weekDates.length - 1]);
    }
    if (holidays?.length) {
      return formatTSWeekFormat(
        `${holidays[0].year}-${holidays[0].month}-${holidays[0].day}`,
        `${holidays[holidays.length - 1].year}-${holidays[holidays.length - 1].month}-${
          holidays[holidays.length - 1].day
        }`
      );
    }
    return '';
  }, [weekDates]);

  const user = useAtomValue(userState);
  const isProxy = user?.role === UserRole.PROXY;

  const { isLoading: allTimesheetsLoading, data: allTimesheets } = useTimesheets({
    userId: user?.id,
  });

  const OPTimesheets = (allTimesheets || []).filter((ts) => ts.user?.id === OPId);
  const timesheetsIds = (isProxy ? OPTimesheets : allTimesheets || [])
    ?.filter((ts) => {
      if (isIssuedTimesheets) {
        return ts.status === TimesheetStatus.ISSUED;
      }
      return true;
    })
    ?.map((ts) => ts.id);

  const havePrev = currentTimesheetId !== timesheetsIds[timesheetsIds.length - 1];
  const haveNext = currentTimesheetId !== timesheetsIds[0];

  const navigateRoute = isAllTimesheets
    ? ROUTES.ALL_TIMESHEETS
    : isIssuedTimesheets
    ? ROUTES.ISSUED_TIMESHEETS
    : ROUTES.ALL_TIMESHEETS;

  return (
    <WeekPaginationWrap>
      <IconButton
        disabled={!havePrev || allTimesheetsLoading}
        onClick={() => {
          const currentIndex = timesheetsIds.findIndex((element) => element === currentTimesheetId);
          const id = timesheetsIds[currentIndex + 1];
          navigate(`/${navigateRoute}/${id}`);
        }}
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: havePrev ? '1' : '0.3' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
      >
        <StyledPrevIcon />
      </IconButton>
      <IconButton
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: haveNext ? '1' : '0.3' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
        disabled={!haveNext || allTimesheetsLoading}
        onClick={() => {
          const currentIndex = timesheetsIds.findIndex((element) => element === currentTimesheetId);
          const id = timesheetsIds[currentIndex - 1];
          navigate(`/${navigateRoute}/${id}`);
        }}
      >
        <StyledNextIcon />
      </IconButton>
      <Typography
        variant='body'
        color={colors.text.main}
        style={{
          lineHeight: 'inherit',
        }}
      >
        {weekName}
      </Typography>
    </WeekPaginationWrap>
  );
}

const WeekPaginationWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 30%;
`;

const StyledPrevIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

const StyledNextIcon = styled(ChevronRightIcon)``;
