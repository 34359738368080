import { useMemo } from "react";
import { WeeklyTimesheet, WeekdayTimesheetTableData, MonthlyTimesheetRow } from "../../../types";
import { calcTotalWeekdayPerDeal } from "./useTimesheetCalculations";

export function useWeekdayTableData (weeklyTimesheet: WeeklyTimesheet[], monthlyTimesheetRow: MonthlyTimesheetRow[]) {
    const tableData: WeekdayTimesheetTableData[] = useMemo(() => {
        return monthlyTimesheetRow.map((row, i) => {
            return {
                id: row.id,
                dealId: row.dealId,
                name: row.name,
                monday: calcTotalWeekdayPerDeal('monday', weeklyTimesheet, row.dealId, row.name),
                tuesday: calcTotalWeekdayPerDeal('tuesday', weeklyTimesheet, row.dealId, row.name),
                wednesday: calcTotalWeekdayPerDeal('wednesday', weeklyTimesheet, row.dealId, row.name),
                thursday: calcTotalWeekdayPerDeal('thursday', weeklyTimesheet, row.dealId, row.name),
                friday: calcTotalWeekdayPerDeal('friday', weeklyTimesheet, row.dealId, row.name),
                avg: Number(monthlyTimesheetRow[i]?.avg.toFixed(2)),
            }
        })
    }, [weeklyTimesheet, monthlyTimesheetRow])

    return tableData;
}