import { Button, IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';
import { Typography } from '../../../components/Typography/Typography';
import { useMemo } from 'react';

interface Props {
  selectedYear: number;
  handleSelectedYear: (year: number) => void;
}

export function TimesheetsSummaryNavigation({ selectedYear, handleSelectedYear }: Props) {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <WeekPaginationWrap isSmallScreen={isSmallScreen}>
      <IconButton
        onClick={() => {
            handleSelectedYear(selectedYear - 1);
        }}
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: '1' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
      >
        <StyledPrevIcon />
      </IconButton>
      <IconButton
        sx={{
          svg: {
            path: { fill: colors.icon.accent, opacity: selectedYear !== currentYear ? '1' : '0.3' },
          },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
        disabled={selectedYear === currentYear}
        onClick={() => {
          handleSelectedYear(selectedYear + 1);
        }}
      >
        <StyledNextIcon />
      </IconButton>
      <Typography
        variant='body'
        color={colors.text.main}
        style={{
          lineHeight: 'inherit',
          fontSize: '1rem',
        }}
      >
        {selectedYear}
      </Typography>
      <Button
        disabled={selectedYear === currentYear}
        sx={{
          '&.Mui-disabled': {
            color: colors.textAccent.disabled,
          },
        }}
        onClick={() => {
          handleSelectedYear(currentYear);
        }}
      >
        {selectedYear !== currentYear ? 'Go to current year' : 'Current year'}
      </Button>
    </WeekPaginationWrap>
  );
}

const WeekPaginationWrap = styled('div')<{ isSmallScreen: boolean }>`
  display: flex;
  justify-content: ${({ isSmallScreen }) => (isSmallScreen ? 'flex-start' : 'center')};
  align-items: center;
  gap: 10px;
  width: ${({ isSmallScreen }) => (isSmallScreen ? '100%' : '30%')};
`;

const StyledPrevIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

const StyledNextIcon = styled(ChevronRightIcon)``;
