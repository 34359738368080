import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Button, IconButton, useTheme } from '@mui/material';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import { NavigationSection } from './NavigationSection';
import { Box, styled } from '@mui/system';
import { ColorTokens } from '../../../themes/structure';
import { ReactComponent as USAFlag } from '../../../assets/icons/usa-flag.svg';
import { ReactComponent as UKFlag } from '../../../assets/icons/uk-flag.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Typography } from '../../../components/Typography/Typography';
import { ActionCell } from '../hooks/useHolidayTableColumns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  dateToUTCDateOnly,
  formatDateToString,
  formatLongDateRange,
} from '../../../utils/formatters';
import { useHolidays } from '../../../queries/hooks/useHolidays';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  addHolidayModalOpen,
  addHolidayModalSelectedStart,
  holidaySearch,
  holidaySelectedCountries,
  holidaySelectedYear,
  holidaySelectedTypes,
  holidaySelectedMonth,
} from '../../../state/UIHolidayScheduleState';
import { CalendarSkeletonLoader } from '../../../components/SkeletonLoader/Calendar.SkeletonLoader';
import { addDays } from 'date-fns';
import { Holiday } from '../../../types';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const CalendarViewTab = () => {
  const { colors } = useTheme();
  const searchValue = useAtomValue(holidaySearch);
  const searchCountries = useAtomValue(holidaySelectedCountries);
  const searchTypes = useAtomValue(holidaySelectedTypes);
  const setHolidayModalOpen = useSetAtom(addHolidayModalOpen);
  const setHolidayModalStart = useSetAtom(addHolidayModalSelectedStart);
  const [selectedYear, setSelectedYear] = useAtom(holidaySelectedYear);
  const setSelectedMonth = useSetAtom(holidaySelectedMonth);
  const [date, setDate] = useState(new Date());
  const currentYear = new Date().getFullYear().toString();

  const { data: holidays, isLoading: isHolidayLoading } = useHolidays(selectedYear);

  useEffect(() => {
    const currentMonth = date.getMonth();
    const newDate = new Date(Number(selectedYear || currentYear), currentMonth);
    setDate(newDate);
  }, [selectedYear, currentYear]);

  const calendarEvents = useMemo(() => {
    return (holidays || [])
      .filter((holiday) => holiday.name.toLowerCase().includes(searchValue.toLowerCase()))
      .filter((holiday) => {
        if (!searchCountries.length) return true;
        const holidayCountries = holiday.country.toLowerCase().split(',');
        return holidayCountries.some((country) =>
          searchCountries.map((search) => search.value.toLowerCase()).includes(country)
        );
      })
      .filter((holiday) => {
        if (!searchTypes.length) return true;
        return searchTypes.map((type) => type.value).includes(holiday.type);
      })
      .map((holiday) => {
        return {
          title: holiday.name,
          start: dateToUTCDateOnly(holiday.startDate),
          end: holiday.endDate
            ? addDays(dateToUTCDateOnly(holiday.endDate), 1)
            : dateToUTCDateOnly(holiday.startDate),
          allDay: true,
          resource: {
            holiday: {
              ...holiday,
              date: holiday.isMultiday
                ? formatLongDateRange(holiday.startDate, holiday.endDate)
                : formatLongDateRange(holiday.startDate),
            },
          },
        };
      });
  }, [holidays, searchValue, searchCountries, searchTypes]);

  const groupedEvents = useMemo(() => {
    const grouped: Record<string, any[]> = {};

    calendarEvents.forEach((event) => {
      const dateKey = format(event.start, 'yyyy-MM-dd');
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push(event);
    });

    return Object.keys(grouped).map((dateKey) => {
      const eventsForDay = grouped[dateKey];
      return {
        ...eventsForDay[0], 
        extraCount: eventsForDay.length - 1,
        extraEvents: eventsForDay.slice(1), 
      };
    });
  }, [calendarEvents]);

  const handleNavigate = useCallback(
    (date: Date) => {
      const newYear = date.getFullYear().toString();
      setDate(new Date(date));
      setSelectedMonth(date.getMonth() + 1);
      if (newYear !== selectedYear) {
        setSelectedYear(newYear);
      }
    },
    [selectedYear, setSelectedYear, setSelectedMonth]
  );

  if (isHolidayLoading) return <CalendarSkeletonLoader />;

  return (
    <StyledCalendar
      localizer={localizer}
      style={{
        height: 800,
        color: colors.text.main,
      }}
      date={date}
      events={groupedEvents}
      view='month'
      components={{
        toolbar: NavigationSection,
        event: CustomEvent,
      }}
      selectable
      onSelectSlot={(slot) => {
        setHolidayModalOpen(true);
        setHolidayModalStart(formatDateToString(slot.start));
      }}
      onNavigate={handleNavigate}
      colors={colors}
    />
  );
};

const CustomEvent = ({ event }: any) => {
  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const { extraCount, extraEvents } = event;
  const { holiday } = event.resource;
  const [modalOpen, setModalOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const allEvents = useMemo(() => {
    return [holiday, ...extraEvents.map((event) => event.resource.holiday)];
  }, [extraEvents, holiday]);

  return (
    <>
      <EventWrapper onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
        <Typography
          variant='caption'
          color={colors.text.secondary}
          style={{
            overflow: 'hidden',
          }}
        >
          {holiday.country.includes('US') && (
            <USAFlag style={{ marginRight: '5px', verticalAlign: 'middle' }} />
          )}
          {holiday.country.includes('UK') && (
            <UKFlag style={{ marginRight: '5px', verticalAlign: 'middle' }} />
          )}
          {holiday.name}
        </Typography>
        {isHovered && <ActionCell holiday={holiday} />}
      </EventWrapper>
      {extraCount > 0 && (
        <Button
          variant='text'
          onClick={() => {
            setModalOpen(true);
          }}
        >
          + {extraCount} {extraCount === 1 ? 'event' : 'events'}
        </Button>
      )}
      {modalOpen && <EventModal events={allEvents} onClose={() => setModalOpen(false)} />}
    </>
  );
};

interface EventModalProps {
  events: Holiday[];
  onClose: () => void;
}

const EventModalWrapper = styled('div')`
  background-color: ${({ colors }: Props) => colors.surfaceBackground.bg4};
  padding: 0 12px 8px;
  border-radius: 4px;
  position: absolute;
  top: -40px;
  left: calc(100vw / 7 - 20px);
  width: calc(100vw / 7 + 10px);
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const EventCell = styled('div')<{ isHovered: boolean; colors: ColorTokens }>`
  background-color: ${({ colors }) => colors.surfaceBackground.highlighted};
  padding: 6px 8px;
  padding-right: ${({ isHovered }) => (isHovered ? '25px' : '0px')};
  margin-bottom: 4px;
  border-radius: 6px;
  position: relative;
`;

const EventModal = ({ events, onClose }: EventModalProps) => {
  const { colors } = useTheme();
  const [hoveredEvent, setHoveredEvent] = useState<number | null>(null);
  const modalRef = useRef(null);
  const actionCellRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(modalRef, () => {
    if (actionCellRef.current) {
      return; 
    }
    onClose();
  });

  return (
    <EventModalWrapper colors={colors} ref={modalRef}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='caption' color={colors.text.main}>
          {`All Events (${events.length})`}
        </Typography>
        <IconButton onClick={onClose} style={{ position: 'relative', left: '15px' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      {events.map((event, i) => (
        <EventCell
          key={i}
          colors={colors}
          onMouseEnter={() => setHoveredEvent(event.id)}
          onMouseLeave={() => setHoveredEvent(null)}
          isHovered={hoveredEvent === event.id}
        >
          <Typography
            variant='caption'
            color={colors.text.secondary}
            style={{ overflow: 'hidden' }}
          >
            {event.country?.includes('US') && (
              <USAFlag style={{ marginRight: '5px', verticalAlign: 'middle' }} />
            )}
            {event.country?.includes('UK') && (
              <UKFlag style={{ marginRight: '5px', verticalAlign: 'middle' }} />
            )}
            {event.name}
          </Typography>
          {hoveredEvent === event.id && (
            <Box
              ref={actionCellRef}
              style={{
                position: 'absolute',
                top: '0px',
                right: '2px',
              }}
            >
              <ActionCell holiday={event} />
            </Box>
          )}
        </EventCell>
      ))}
    </EventModalWrapper>
  );
};

interface Props {
  colors: ColorTokens;
}

const StyledCalendar = styled(Calendar)`
  .rbc-month-view {
    border: 1px solid ${({ colors }: Props) => colors.border.default};
  }
  .rbc-header {
    border-bottom: 1px solid ${({ colors }: Props) => colors.border.default};
  }
  .rbc-header + .rbc-header {
    border-left: 1px solid ${({ colors }: Props) => colors.border.default};
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${({ colors }: Props) => colors.border.default};
    cursor: pointer;
  }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${({ colors }: Props) => colors.border.default};
    cursor: pointer;
  }
  .rbc-month-row {
    overflow: visible;
  }
  .rbc-off-range-bg {
    background-color: ${({ colors }: Props) => colors.surfaceBackground.bg2};
  }
  .rbc-off-range {
    color: ${({ colors }: Props) => colors.textAccent.disabled};
  }
  .rbc-now > button {
    background-color: ${({ colors }: Props) => colors.iconStatus.active};
    color: ${({ colors }: Props) => colors.textAccent.inverse};
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: transparent;
    padding: 0;
  }
  .rbc-event .rbc-event-content > div:first-of-type {
    position: relative;
    background-color: ${({ colors }: Props) => colors.surfaceBackground.highlighted};
    margin: 4px 8px;
    height: 35px;
    border-radius: 6px;
    padding-left: 12px;
    width: 90%;
    display: flex;
    align-items: center;
    jusify-content: space-between;
  }
  .rbc-event-content {
    color: ${({ colors }: Props) => colors.text.secondary};
    font-size: 12px;
  }
  .rbc-row-segment {
    position: relative;
  }
`;

const EventWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
