import { Box, Chip, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import TooltipComponent from '../../../components/TooltipComponent/TooltipComponent';
import { Typography } from '../../../components/Typography/Typography';
import { Partner } from '../../../types';

type Props = {
  operatingPartners: Partner[] | undefined;
};

const maxDealsToShow = 4;
export function OperatingPartnersCell({ operatingPartners }: Props) {
  const { colors } = useTheme();
  const [restDealsTooltipOpen, setRestDealsTooltipOpen] = useState(false);

  const handleRestDealsTooltipOpen = useCallback(() => {
    setRestDealsTooltipOpen(true);
  }, []);

  const handleRestDealsTooltipClose = useCallback(() => {
    setRestDealsTooltipOpen(false);
  }, []);

  const slicedOperatingPartners = operatingPartners?.slice(0, maxDealsToShow) || [];

  const restDeals = operatingPartners?.slice(maxDealsToShow, operatingPartners.length);

  const restDealsNumber = restDeals?.length;

  return (
    <Box display={'flex'} gap={1} overflow={'scroll'}>
      {slicedOperatingPartners.map((partner) => {
        return (
          <AvatarCell
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
            key={partner.id}
            name={partner?.name || ''}
          />
        );
      })}
      {restDealsNumber ? (
        <TooltipComponent
          open={!!restDealsNumber && restDealsTooltipOpen}
          onOpen={handleRestDealsTooltipOpen}
          onClose={handleRestDealsTooltipClose}
          content={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {restDeals?.map((item) => {
                return (
                  <Typography variant='body' color={colors.text.main} key={item.id}>
                    {item.name}
                  </Typography>
                );
              })}
            </Box>
          }
        >
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            onClick={handleRestDealsTooltipOpen}
            label={
              <Typography variant='body' color={colors.text.main}>
                + {restDealsNumber} OP
              </Typography>
            }
          />
        </TooltipComponent>
      ) : null}
    </Box>
  );
}
