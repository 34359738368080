import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { ProxyTimesheetStatusesTable } from './components/ProxyTimesheetStatusesTable';


export function ProxyTimesheetsSummary() {
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='Timesheets Summary'
        description=''
        breadcrumbs={[{ title: 'Timesheets Summary' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <ProxyTimesheetStatusesTable />
      </ContentWrapper>
    </>
  );
}
