import { styled, useTheme } from '@mui/material';
import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { Typography } from '../../../components/Typography/Typography';
import { useHolidayYears } from '../../../queries/hooks/useHolidayYears';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { useEffect, useMemo, useState } from 'react';
import { SelectItem } from '../../../types';
import { FormField } from '../../../components/FormField/FormField';
import { useHolidays } from '../../../queries/hooks/useHolidays';
import { exportHolidays } from '../hooks/useHolidayExcel';
import { useToastMessage } from '../../../hooks/useToastMessage';

interface ExportHolidaysModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export function ExportHolidaysModal({ isOpen, handleClose }: ExportHolidaysModalProps) {
  const { colors } = useTheme();
  const [selectedYear, setSelectedYear] = useState<SelectItem | null>(null);
  const { pushSuccessToast } = useToastMessage();

  const { data: holidayYears, isLoading: isLoadingHolidayYears } = useHolidayYears();
  const { data: holidays, isLoading: isLoadingHolidays } = useHolidays(selectedYear?.value);

  useEffect(() => {
    if (holidayYears?.length) {
      setSelectedYear({
        value: holidayYears[0].toString(),
        id: holidayYears[0],
      });
    }
  }, [holidayYears]);

  const holidayYearsOptions = useMemo(() => {
    if (!holidayYears || !holidayYears.length) return [];
    return holidayYears.map((year) => ({ value: year.toString(), id: year }));
  }, [holidayYears]);

  const isSubmitDisabled = useMemo(() => {
    return !selectedYear || !holidays?.length || isLoadingHolidayYears || isLoadingHolidays;
  }, [holidays?.length, isLoadingHolidayYears, isLoadingHolidays, selectedYear]);

  const handleExport = () => {
    exportHolidays(holidays ?? []);
    pushSuccessToast({
      title: 'Events successfully exported',
      message: '',
    });
    handleClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleClose}
      actionButtonsProps={{
        onSubmit: handleExport,
        isSubmitDisabled,
        onCancel: handleClose,
        submitText: 'Export',
      }}
      title='Export Events'
      top='80px'
      width='660px'
    >
      <>
        <ExportWrapper>
          <Typography variant='h5' color={colors.text.main}>
            To export the calendar, select a year. The data will be saved in Excel format.
          </Typography>
          <FormField label={'Event Year'}>
            <SingleSelect
              style={{ width: '150px' }}
              listboxStyle={{ height: '195px' }}
              options={holidayYearsOptions}
              value={selectedYear}
              onChange={(_, value) => {
                setSelectedYear(value);
              }}
              disablePortal
              fieldPlaceholder='Select Year'
            />
          </FormField>
        </ExportWrapper>
        {holidays && (
          <InfoWrapper>
            <Typography variant='body' color={colors.text.secondary}>
              Exporting :
            </Typography>
            <Typography variant='subtitle1' color={colors.text.main}>
              {holidays.length} {holidays.length <= 1 ? 'Event' : 'Events'}
            </Typography>
          </InfoWrapper>
        )}
      </>
    </ModalComponent>
  );
}

const ExportWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  margin-bottom: 14px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg4};
`;

const InfoWrapper = styled('div')`
  display: flex;
  gap: 6px;
  padding: 12px;
  margin-bottom: 14px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
`;
