import { useAtomValue } from 'jotai';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { ProxyAssignmentsTable } from './components/ProxyAssignmentsTable';
import { isSmallScreenState } from '../../state/UIState';

export const ProxyAssignment = () => {
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='Proxy Assignment'
        description=''
        breadcrumbs={[{ title: 'Administration' }, { title: 'Proxy Assignment' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <ProxyAssignmentsTable />
      </ContentWrapper>
    </>
  );
};
