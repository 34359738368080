import { Button, useTheme, Box } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { TableV1 } from '../../../components/Table/TableV1';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';
import { useUsersWithTsPeriodData } from '../../../hooks/useUsersWithTsPeriodData';
import { UserWithTsPeriodData } from '../../../types';
import { useIcons } from '../../../hooks/useIcons';
import { useSearchParams } from 'react-router-dom';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';
import { TableContentSkeletonLoader } from '../../AccountantTimesheetStatuses/components/TableContentSkeletonLoader';
import { useProxyTsStatusesColumns } from '../hooks/useProxyTsStatusesColumns';
import { FilterSection } from '../../AccountantTimesheetStatuses/components/FilterSection';
import { ExportMultiTimesheetModal } from './ExportTimesheetsModal';

export function ProxyTimesheetStatusesTable() {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const location = useLocation();
  const { DownLoadIcon } = useIcons();
  const { pushWarningToast } = useToastMessage();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');

  const { isLoading: timesheetsLoading, data: operatingPartners } = useAllUserOperatingPartners(
    yearQueryParam || ''
  );

  const operatingPartnersWithMappedPeriods = useUsersWithTsPeriodData(operatingPartners);
  const columns = useProxyTsStatusesColumns(operatingPartners || []);

  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const table = useReactTable({
    data: (operatingPartnersWithMappedPeriods as UserWithTsPeriodData[]) ?? [],
    columns,
    state: {
      rowSelection,
      sorting,
      columnFilters,
    },
    onRowSelectionChange: setRowSelection,
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  const onExportModalOpen = useCallback(() => {
    const selectedIds = Object.keys(table.getState().rowSelection);
    if (!selectedIds.length) {
      pushWarningToast({
        title: 'Select timesheets to export',
        message:
          'Click on the timesheets in Timesheets Overview table to export selected timesheets.',
      });
    } else {
      setExportModalOpen(true);
      searchParams.set('ids', selectedIds.join(','));
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, table, pushWarningToast]);

  const onExportModalClose = useCallback(() => {
    setExportModalOpen(false);
    searchParams.delete('ids');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <div>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <FilterSection table={table} columnFilters={columnFilters} />
        <Button
          variant='outlined'
          startIcon={<DownLoadIcon />}
          style={{
            backgroundColor: colors.surfaceBackground.bg1,
            marginBottom: isSmallScreen ? '10px' : '0px',
          }}
          onClick={onExportModalOpen}
        >
          Export
        </Button>
      </Box>
      {timesheetsLoading || !operatingPartners ? (
        <TableContentSkeletonLoader />
      ) : (
        <TableV1
          table={table}
          height='auto'
          isLastColumnSticky={false}
          disableRowHover
          wrapperStyle={{
            maxHeight: 'calc(100vh - 288px)',
            overflowX: 'scroll',
            border: `1px solid ${colors.border.default}`,
            overflowY: 'scroll',
          }}
        />
      )}
      {exportModalOpen && (
        <ExportMultiTimesheetModal isOpen={exportModalOpen} onClose={onExportModalClose} />
      )}
    </div>
  );
}
