import { useCallback, useState } from 'react';
import { Tabs, Tab, Box, useTheme, Button } from '@mui/material';
import { SingleTimesheetHeader } from './SingleTimesheetHeader';
import { AccountantSingleTimesheetWeekdayTable } from './AccountantSingleTimesheetWeekdayTable';
import { AccountantSingleTimesheetWeekTable } from './AccountantSingleTimesheetWeekTable';
import { useParams } from 'react-router-dom';
import { DownloadCSVLink } from './DownloadCSVButton';
import { useMonthlySingleTimesheet } from '../../../queries/hooks/useMonthlySingleTimesheet';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { AccountantTSView as TimesheetTab, TimesheetStatus } from '../../../types';
import { ReactComponent as ReopenIcon } from '../../../assets/icons/reupload-blue.svg';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';
import { SendBackTimesheetModal } from './SendBackTimesheetModal';

export function AccountantSingleTimesheetTable() {
  const { colors } = useTheme();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TimesheetTab.weekday);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });
  const isApprovedTS = singleTimesheet?.status === TimesheetStatus.CLOSED;

  const handleTabChange = (_: React.SyntheticEvent, newValue: TimesheetTab) => {
    setSelectedTab(newValue);
  };

    const toggleReopenTsOpen = useCallback(() => {
      setReopenModalOpen((prev) => !prev);
    }, []);

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab
            label='By Weekday'
            value={TimesheetTab.weekday}
            sx={{
              color: colors.text.main,
              textTransform: 'none',
            }}
          />
          <Tab
            label='By Week'
            value={TimesheetTab.week}
            sx={{
              color: colors.text.main,
              textTransform: 'none',
            }}
          />
        </Tabs>
        {isApprovedTS && (
          <Box display='flex' gap='8px'>
            <DownloadCSVLink selectedTab={selectedTab} timesheet={singleTimesheet} />
            <Button
              startIcon={<ReopenIcon />}
              variant='outlined'
              onClick={() => setReopenModalOpen(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: isSmallScreen ? 1 : 0,
              }}
              style={{
                backgroundColor: colors.surfaceBackground.bg1,
              }}
            >
                Reopen
            </Button>
          </Box>
        )}
      </Box>
      {selectedTab === TimesheetTab.weekday && (
        <AccountantSingleTimesheetWeekdayTable
          weeklyTimesheet={singleTimesheet.weeklyTimesheets}
          timesheetRows={singleTimesheet.timesheetRows}
        />
      )}
      {selectedTab === TimesheetTab.week && (
        <AccountantSingleTimesheetWeekTable weeklyTimesheet={singleTimesheet.weeklyTimesheets} />
      )}
      {reopenModalOpen && (
        <SendBackTimesheetModal
          timesheet={singleTimesheet}
          isOpen={reopenModalOpen}
          toggleOpen={toggleReopenTsOpen}
        />
      )}
    </>
  );
}
