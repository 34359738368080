import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { Holiday } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateHolidays } from './useHolidays';
import { useInvalidateHolidayYears } from './useHolidayYears';

type Props = {
  payload: EditHolidayPayload;
  multidayId: string;
};

type EditHolidayPayload = {
  type: string;
  name: string;
  country: string;
  startDate: string;
  endDate: string;
};

async function editHoliday({ payload, multidayId }: Props): Promise<Holiday[]> {
  const { data } = await Http.axios.patch<EditHolidayPayload, Holiday[]>(
    `holiday/${multidayId}`,
    payload
  );
  return data;
}

export const useEditHoliday = () => {
  const invalidateHolidays = useInvalidateHolidays();
  const invalidateHolidayYears = useInvalidateHolidayYears();

  return useMutation(editHoliday, {
    mutationKey: MUTATION_KEYS.EDIT_HOLIDAY_KEY,
    onSuccess: () => {
      invalidateHolidays();
      invalidateHolidayYears();
    },
  });
};
