import { useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { MontlyTimesheetsBulkActions } from '../../../components/MontlyTimesheetsBulkActions/MontlyTimesheetsBulkActions';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { TableV1 } from '../../../components/Table/TableV1';
import { useMonthlyTimesheets } from '../../../queries/hooks/useMonthlyTimesheets';
import { userState } from '../../../state/UIState';
import { MonthlyTimesheet, TimesheetStatus, UserRole } from '../../../types';
import { useSubmitedTimesheetsTableColumns } from '../hooks/useSubmitedTimesheetsTableColumns';
import { FilterSection } from './FilterSection';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { mapTsStatuses } from '../../../utils/tsMappers';

export function SubmitedTimesheeetsTable() {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const [rowSelection, setRowSelection] = useState({});

  const { isLoading: areIssuedTimesheetsLoading, data: timesheets } = useMonthlyTimesheets({
    userId: user?.id as number,
  });

  const submittedTimesheets = useMemo(() => {
    if (!timesheets) return [];
    return timesheets.filter(
      (ts) =>
        ts.status === TimesheetStatus.PENDING_OP_MANAGER &&
        !ts.approvedByOpManagers?.some((manager) => manager.id === user?.id)
    );
  }, [timesheets, user?.id]);

  const statusMappedData = useMemo(() => {
    if (!submittedTimesheets) return [];
    return mapTsStatuses<MonthlyTimesheet>(submittedTimesheets, UserRole.OP_MANAGER);
  }, [submittedTimesheets]);

  const columns = useSubmitedTimesheetsTableColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: statusMappedData ?? [],
    columns,
    state: {
      rowSelection,
      sorting,
      columnFilters,
    },
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (issuedItem) => String(issuedItem.id),
  });

  if (areIssuedTimesheetsLoading || !submittedTimesheets) return <TableSkeletonLoader />;
  const hasSelectedItems = !!Object.keys(rowSelection)?.length;

  return (
    <div>
      {hasSelectedItems && (
        <MontlyTimesheetsBulkActions
          table={table}
          approveStatus={TimesheetStatus.PENDING_DEAL_OWNERS}
          sendToLabel='Deal Owner'
          onApprove={() => navigate(`/${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`)}
        />
      )}

      <FilterSection table={table} columnFilters={columnFilters} />
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </div>
  );
}
