import { useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { Timesheet, UserRole } from '../../../types';
import { formatDate } from '../../../utils/formatters';

type Props = {
  updatedBy: Timesheet['updatedBy'];
  updated_at: Timesheet['updated_at'];
};
export function LastEditedCell({ updatedBy, updated_at }: Props) {
  const { colors } = useTheme();

  const proxyUserRole = updatedBy?.role === UserRole.PROXY;

  const formatedUpdatedAt = formatDate(updated_at || '');
  return (
    <Typography variant='body' color={colors.text.main}>
      {formatedUpdatedAt}

      {proxyUserRole ? ` by ${updatedBy?.name}` : null}
    </Typography>
  );
}
