import { useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { TableV1 } from '../../../components/Table/TableV1';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';
import { useAccountantTsOverviewColumns } from '../hooks/useAccountantTsOverviewColumns';
import { FilterSection } from './FilterSection';
import { TableContentSkeletonLoader } from './TableContentSkeletonLoader';
import { UserWithTsPeriodData } from '../../../types';
import { useUsersWithTsPeriodData } from '../../../hooks/useUsersWithTsPeriodData';

export function AccontantOpOverviewTable() {
  const { colors } = useTheme();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');

  const { isLoading: timesheetsLoading, data: operatingPartners } = useAllUserOperatingPartners(
    yearQueryParam || ''
  );

  const operatingPartnersWithMappedPeriods = useUsersWithTsPeriodData(operatingPartners);
  const columns = useAccountantTsOverviewColumns(operatingPartners || []);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: (operatingPartnersWithMappedPeriods as UserWithTsPeriodData[]) ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  return (
    <div>
      <FilterSection table={table} columnFilters={columnFilters} />
      {timesheetsLoading || !operatingPartners ? (
        <TableContentSkeletonLoader />
      ) : (
        <TableV1
          table={table}
          height='auto'
          isLastColumnSticky={false}
          wrapperStyle={{
            maxHeight: 'calc(100vh - 288px)',
            overflowX: 'scroll',
            border: `1px solid ${colors.border.default}`,
          }}
        />
      )}
    </div>
  );
}
