import { useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { TableV1 } from '../../../components/Table/TableV1';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';
import { useOperatingPartnerTableColumn } from '../hooks/useOperatingPartnerTableColumn';
import { FilterSection } from './FilterSection';

export function OperatingPartnerTable() {
  const { colors } = useTheme();

  const { isLoading: operationgPartnersLoading, data: operatingPartners } =
    useAllUserOperatingPartners();

  const columns = useOperatingPartnerTableColumn();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: operatingPartners ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  if (operationgPartnersLoading || !operatingPartners) return <TableSkeletonLoader />;

  return (
    <div>
      <FilterSection table={table} columnFilters={columnFilters} />
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </div>
  );
}
