import { Box, IconButton, styled, useTheme } from '@mui/material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/chevron-down.svg';
import { OpManagerSummaryRowData } from '../../../types';
import { getMonthLabelShort } from '../../../utils/formatters';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

type MonthKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

const columnHelper = createColumnHelper<OpManagerSummaryRowData>();

export const useOpManagerTimesheetsSummaryColumns = ({ nestedTable }: { nestedTable: boolean }) => {
  const { colors } = useTheme();

  const columns = useMemo(() => {
    const cols: ColumnDef<OpManagerSummaryRowData, any>[] = [];
    cols.push(
      columnHelper.accessor(nestedTable ? 'userName' : 'dealName', {
        cell: (info) =>
          nestedTable ? (
            <Typography variant='body' color={colors.text.main} style={{
              paddingLeft: '62px'
            }}>
              {info.getValue()}
            </Typography>
          ) : (
            <Box
              style={{
                height: '48px',
                borderRadius: '4px 0 0 4px',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                overflow: 'hidden',
              }}
            >
              <IconButton
                size='small'
                style={{ padding: 0, visibility: info.row.getCanExpand() ? 'visible' : 'hidden' }}
                onClick={info.row.getCanExpand() ? info.row.getToggleExpandedHandler() : undefined}
              >
                {info.row.getIsExpanded() ? <ArrowDownIcon /> : <ArrowRightIcon />}
              </IconButton>
              <AvatarCell
                avatarBoxStyle={{
                  background: colors.surfaceIndicator.needsReview,
                }}
                avatarLetterStyle={{
                  color: colors.text.main,
                }}
                name={info.getValue()}
              />
            </Box>
          ),
        header: () => <TableHeaderCell text={nestedTable ? 'User' : 'Deal'} />,
        meta: {
          width: '260px',
          maxWidth: '260px',
          minWidth: '260px',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none`, paddingLeft: '16px' },
        },
      })
    );

    for (let i = 1; i <= 12; i++) {
      cols.push(
        columnHelper.accessor(`${i}` as MonthKey, {
          cell: (info) => (
            <CellWrapper>
              <Typography variant='body' color={colors.text.caption} style={{ textAlign: 'right' }}>
                {info.getValue() || info.getValue() === 0 ? `${info.getValue().toFixed(2)}%` : '-'}
              </Typography>
            </CellWrapper>
          ),
          header: () => <TableHeaderCell text={getMonthLabelShort(i)} />,
          meta: {
            width: '75px',
            maxWidth: '75px',
            minWidth: '75px',
            tdStyles: { paddingLeft: '16px', borderRight: `none`, borderLeft: 'none' },
            thStyles: { borderRight: `none`, paddingLeft: '16px', borderLeft: 'none' },
          },
        })
      );
    }

    return cols;
  }, [colors.surfaceIndicator.needsReview, colors.text.caption, colors.text.main, nestedTable]);

  return columns;
};
