import { Button, styled, useTheme } from '@mui/material';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { Typography } from '../../../components/Typography/Typography';
import {
  allTableSelectedOPs,
  allTableSelectedDate,
  allTableSelectedStatus,
} from '../../../state/UIDealOwnerState';
import { MonthlyTimesheet, SelectItem } from '../../../types';
import { isSmallScreenState, userState } from '../../../state/UIState';
import { useMonthlyTimesheets } from '../../../queries/hooks/useMonthlyTimesheets';
import { Multiselect } from '../../../components/Multiselect/Multiselect';

type Props = {
  columnFilters: ColumnFiltersState;
  table: Table<MonthlyTimesheet>;
};

export function FilterSection({ table, columnFilters }: Props) {
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const [dateFilterValue, setDateFilterValue] = useAtom(allTableSelectedDate);
  const [selectedStatus, setSelectedStatus] = useAtom(allTableSelectedStatus);
  const [selectedOPs, setSelectedOPs] = useAtom(allTableSelectedOPs);
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const { data: timesheets } = useMonthlyTimesheets({
    userId: user?.id as number,
  });

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('user')?.setFilterValue(OPs);
      }
    },
    [setSelectedOPs, table]
  );

  const onChangeStatusFilter = useCallback(
    (status: SelectItem | null) => {
      setSelectedStatus(status);

      table.getColumn('status')?.setFilterValue(status?.id ?? '');
    },
    [setSelectedStatus, table]
  );

  const onChangeDateFilter = useCallback(
    (date: Date | null) => {
      //add two hours to date to match the date format
      date?.setHours(date.getHours() + 2);
      const filterValue = date ? date.toISOString() : null;
      setDateFilterValue(filterValue);
    },
    [setDateFilterValue]
  );

  const onFiltersReset = useCallback(() => {
    setSelectedOPs([]);
    setDateFilterValue(null);
    setSelectedStatus(null);
  }, [setDateFilterValue, setSelectedOPs, setSelectedStatus]);

  const sortedStatusList = useMemo(
    () =>
      Array.from(table.getColumn('status')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value) => ({ id: value, value })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, timesheets]
  );

  const sortedOPList = useMemo(
    () => {
      const allOPs = Array.from(table.getColumn('user')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((user) => ({ id: user.id, value: user.name }));

      const uniqueOPs = Array.from(new Map(allOPs.map((item) => [item.id, item])).values());
      return uniqueOPs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const isResetDisabled = useMemo(() => {
    const filterValues = columnFilters.map((filter) => filter.value);
    if (filterValues.every((val: any) => !val || val.length === 0)) return true;
    return false;
  }, [columnFilters]);

  useEffect(() => {
    table.getColumn('month')?.setFilterValue(dateFilterValue);
  }, [dateFilterValue, table]);

  useEffect(() => {
    table.getColumn('user')?.setFilterValue(selectedOPs);
  }, [selectedOPs, table]);

  useEffect(() => {
    table.getColumn('status')?.setFilterValue(selectedStatus?.id ?? '');
  }, [selectedStatus, table]);

  return (
    <Container>
      <Multiselect
        style={{ width: isSmallScreen ? '130px' : '200px', marginRight: '14px' }}
        fieldPlaceholder={isSmallScreen ? 'OP' : 'Select OP'}
        options={sortedOPList as SelectItem[]}
        onChange={(_, OPs) => onChangeOPFilter(OPs)}
        value={selectedOPs}
      />

      <SingleSelect
        style={{ width: isSmallScreen ? '150px' : '200px', marginRight: '14px' }}
        listboxStyle={{ height: '195px' }}
        options={sortedStatusList as SelectItem[]}
        value={selectedStatus}
        onChange={(_, status) => onChangeStatusFilter(status)}
        disablePortal
        fieldPlaceholder={isSmallScreen ? 'Status' : 'Timesheet Status'}
      />

      <DatePicker
        clearable
        selectedDate={dateFilterValue}
        setSelectedDate={onChangeDateFilter}
        disableFuture
        views={['year', 'month']}
        inputFormat='MM/yyyy'
        placeholder={isSmallScreen ? 'Month and Year' : 'Select Month and Year'}
      />
      <Typography variant='caption' color={colors.text.secondary}>
        {`${table.getRowCount()} timesheets`}
      </Typography>
      <Button
        onClick={onFiltersReset}
        variant='text'
        style={{ height: '28px', marginTop: '2px' }}
        disabled={isResetDisabled}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
        }}
      >
        <Typography variant='subtitle1' color={colors.textAccent.default}>
          Reset Filters
        </Typography>
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;
