import { Box, Chip, Icon, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { WeeklyTimesheet } from '../../../../types';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-circle.svg';
import { AvatarCell } from '../../../../components/Table/CellRenderers/AvatarCell';
import { text } from '../../../../themes/text';
import { formatDateWithTime } from '../../../../utils/formatters';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../../state/UIState';

interface RejectedTimesheetBannerProps {
  timesheet: WeeklyTimesheet;
}

function RejectedTimesheetBanner({ timesheet }: RejectedTimesheetBannerProps) {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const rejectedLog = timesheet.timesheetLogs[0];

  return (
    <Banner isSmallScreen={isSmallScreen}>
      <TitleWrapper>
        <Icon sx={{ svg: { path: { fill: colors.iconStatus.error } } }}>
          <WarningIcon />
        </Icon>
        <Typography variant='h5' color={colors.textStatus.error}>
          Timesheet was Sent Back
        </Typography>
      </TitleWrapper>
      {rejectedLog && (
        <InfoWrapper isSmallScreen={isSmallScreen}>
          <Box>
            <Typography
              variant='caption'
              color={colors.text.main}
              style={{
                marginBottom: isSmallScreen ? '4px' : '8px',
              }}
            >
              Comment
            </Typography>
            <Typography variant='body' color={colors.text.main} style={{
              width: '100%'
            }}>
              {rejectedLog.note || 'No comment provided'}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant='caption'
              color={colors.text.main}
              style={{
                marginBottom: '8px',
              }}
            >
              Rejected by
            </Typography>
            <Box display='flex' alignItems='center' gap='6px'>
              <AvatarCell
                name={rejectedLog?.createdBy?.name ?? ''}
                avatarBoxStyle={{
                  width: 34,
                  height: 34,
                  minWidth: 34,
                  maxWidth: 34,
                  background: colors.surfaceIndicator.needsReview,
                }}
                avatarLetterStyle={{
                  color: colors.text.main,
                  fontSize: 14,
                }}
                avatarNameStyle={{
                  fontFamily: text.font.basierRegular,
                  fontSize: 14
                }}
              />
              <Chip
                style={{
                  backgroundColor: colors.iconStatus.noAction,
                  minWidth: '66px',
                  height: '28px',
                }}
                label={
                  <Typography variant='body' color={colors.text.main}>
                    {rejectedLog?.createdBy?.role}
                  </Typography>
                }
              />
            </Box>
          </Box>
          <Box>
            <Typography
              variant='caption'
              color={colors.text.main}
              style={{
                marginBottom: isSmallScreen ? '4px' : '8px',
              }}
            >
              Date
            </Typography>
            <Typography variant='body' color={colors.text.main}>
              {formatDateWithTime(rejectedLog.updated_at)}
            </Typography>
          </Box>
        </InfoWrapper>
      )}
    </Banner>
  );
}

export default RejectedTimesheetBanner;

const Banner = styled('div')<{isSmallScreen: boolean}>`
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  padding: ${({isSmallScreen}) => isSmallScreen ? '8px' : '1rem'};
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoWrapper = styled('div')<{isSmallScreen: boolean}>`
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: 4px;
  margin-top: 1rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: ${({isSmallScreen}) => isSmallScreen ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'};
  gap: ${({isSmallScreen}) => isSmallScreen ? '12px' : '8px'};
  & :nth-child(2) {
    justify-self: ${({isSmallScreen}) => isSmallScreen ? 'start' : 'center'};
  }
  & :nth-child(3) {
    justify-self: ${({isSmallScreen}) => isSmallScreen ? 'start' : 'end'};
  }
`;
