import { Box, Button, IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as FileIcon } from '../../../assets/icons/file-blue.svg';
import { ReactComponent as ReuploadIcon } from '../../../assets/icons/reupload-blue.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-gray.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-circle.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useDropzone } from 'react-dropzone';
import { TableV1 } from '../../../components/Table/TableV1';
import { useUploadedHolidayTableColumns } from '../hooks/useUploadedHolidayTableColumns';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { HolidayExcelRow } from '../../../types';

interface UploadedHolidaysSectionProps {
  file: File;
  holidays: HolidayExcelRow[];
  hasErrors: boolean;
  onReuploadFile: (acceptedFiles: File[]) => void;
  onClearFile: () => void;
  onRemoveHoliday: (i: number) => void;
}

export const UploadedHolidaysSection = ({
  file,
  holidays,
  hasErrors,
  onReuploadFile,
  onClearFile,
  onRemoveHoliday,
}: UploadedHolidaysSectionProps) => {
  const { colors } = useTheme();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onReuploadFile,
    maxFiles: 1,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
  });

  const columns = useUploadedHolidayTableColumns(onRemoveHoliday);

  const table = useReactTable({
    data: holidays ?? [],
    columns,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <UploadedHolidaysWrapper>
        <FileIcon />
        <Box flexGrow={1}>
          <Typography variant='subtitle1' color={colors.text.main}>
            {file.name}
          </Typography>
          <Typography variant='srOnly' color={colors.text.caption}>
            Imported {holidays.length} {holidays.length === 1 ? 'event' : 'events'}
          </Typography>
        </Box>
        <Button
          variant='outlined'
          startIcon={<ReuploadIcon />}
          style={{
            backgroundColor: 'transparent',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} accept='.xlsx, .csv' />
          Replace File
        </Button>
        <IconButton onClick={onClearFile}>
          <CloseIcon />
        </IconButton>
      </UploadedHolidaysWrapper>
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: hasErrors ? '25vh' : '30vh',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
        tableStyle={{
          minWidth: 'unset'
        }}
      />
      {hasErrors ? (
        <ErrorWrapper>
          <Box display='flex' alignItems='center' gap='4px' marginBottom='6px'>
            <ErrorIcon />
            <Typography variant='subtitle1' color={colors.textStatus.error}>
              Some entries in file are invalid! Please review the data and adjust the file accordingly
            </Typography>
          </Box>
          <Box>
            <Typography variant='srOnly' color={colors.textStatus.error}>
              - Name is a required field
            </Typography>
            <Typography variant='srOnly' color={colors.textStatus.error}>
              - Country values should be US or UK or both separated by comma
            </Typography>
            <Typography variant='srOnly' color={colors.textStatus.error}>
              - Type values should be Bank Holiday, Duvet Day, Offsite Event, Company Event, General
              Restructuring or Other
            </Typography>
            <Typography variant='srOnly' color={colors.textStatus.error}>
              - Start Date should be in the YYYY-MM-DD format
            </Typography>
            <Typography variant='srOnly' color={colors.textStatus.error}>
              - For single-day events, leave End Date empty
            </Typography>
          </Box>
        </ErrorWrapper>
      ) : null}
    </>
  );
};

const UploadedHolidaysWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  gap: 6px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  border: 1px solid ${({ theme }) => theme.colors.border.default};
`;

const ErrorWrapper = styled('div')`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg4};
`;
