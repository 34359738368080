import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { MonthlyTimesheet } from '../../types';
import { QUERY_KEYS } from '../constants';

interface Props {
  userId: number;
  yearQueryParam?: string;
}

async function fetchMonthlyTimesheets(
  id: number,
  yearQueryParam?: string
): Promise<MonthlyTimesheet[]> {
  const url = yearQueryParam
    ? `/monthly-timesheet/user/${id}?year=${yearQueryParam}`
    : `/monthly-timesheet/user/${id}`;
  const { data } = await Http.axios.get<MonthlyTimesheet[]>(url);
  return data;
}

export const useMonthlyTimesheets = ({ userId, yearQueryParam }: Props) => {
  return useQuery(
    [QUERY_KEYS.MONTHLY_TIMESHEET, userId, yearQueryParam],
    () => fetchMonthlyTimesheets(userId, yearQueryParam),
    {
      staleTime: Infinity,
      enabled: !!userId,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useInvalidateMonthlyTimesheets = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.MONTHLY_TIMESHEET,
    });
  };
};
