import { useCallback, useEffect, useMemo } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { Typography } from '../../../components/Typography/Typography';
import { SelectItem, User } from '../../../types';
import { opPartnersTableSearch, opPartnersSelectedOPs } from '../../../state/UIOpManagerState';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';
import { isSmallScreenState } from '../../../state/UIState';

type Props = {
  columnFilters: ColumnFiltersState;
  table: Table<User>;
};

export function FilterSection({ table, columnFilters }: Props) {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const [searchValue, setSearchValue] = useAtom(opPartnersTableSearch);
  const [selectedOPs, setSelectedOPs] = useAtom(opPartnersSelectedOPs);

  const { data: operatingPartners } = useAllUserOperatingPartners();

  const sortedOPList = useMemo(
    () => {
      if (!operatingPartners) return [];
      const allOPs = operatingPartners.map((op) => ({ id: op.id, value: op.name }));
      return allOPs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const isResetDisabled = useMemo(() => {
    const filterValues = columnFilters.map((filter) => filter.value);
    if (filterValues.every((val: any) => !val || val.length === 0)) return true;
    return false;
  }, [columnFilters]);

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('name')?.setFilterValue(OPs);
      }
    },
    [setSelectedOPs, table]
  );

  const onFiltersReset = useCallback(() => {
    setSelectedOPs([]);
    setSearchValue('');
  }, [setSelectedOPs, setSearchValue]);

  useEffect(() => {
    table.getColumn('name')?.setFilterValue(selectedOPs);
  }, [selectedOPs, table]);

  useEffect(() => {
    table.getColumn('operatingPartnerDeals')?.setFilterValue(searchValue);
  }, [searchValue, table]);

  return (
    <Container>
      <SearchInput
        placeholder='Search'
        style={{ width: isSmallScreen ? '150px' : '370px', height: '36px' }}
        onClear={() => setSearchValue('')}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue ?? ''}
      />

      <Multiselect
        style={{ width: isSmallScreen ? '150px' : '200px', marginRight: '14px' }}
        fieldPlaceholder={isSmallScreen ? 'OP' : 'Select OP'}
        options={sortedOPList}
        onChange={(_, OPs) => onChangeOPFilter(OPs)}
        value={selectedOPs}
      />
      <Button
        onClick={onFiltersReset}
        variant='text'
        style={{ height: '28px', marginTop: '2px' }}
        disabled={isResetDisabled}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
        }}
      >
        <Typography variant='subtitle1' color={colors.textAccent.default}>
          Reset Filters
        </Typography>
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;
