import { Button, styled, useTheme } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import { BulkApproveTimesheetModal } from './BulkApproveTimesheetModal';
import { Typography } from '../Typography/Typography';
import { MonthlyTimesheet, TimesheetStatus } from '../../types';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

type Props = {
  table: Table<MonthlyTimesheet>;
  approveStatus: TimesheetStatus;
  sendToLabel?: string;
  onApprove: () => void;
};

export function MontlyTimesheetsBulkActions({
  table,
  approveStatus,
  sendToLabel,
  onApprove,
}: Props) {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const selectedTimesheets = table.getSelectedRowModel().rows.map((row) => row.original);

  const handleResetSelection = useCallback(() => {
    table.resetRowSelection();
  }, [table]);

  const [approveTimesheetOpen, setApproveTimesheetOpen] = useState(false);

  const toggleApproveTsOpen = useCallback(() => {
    setApproveTimesheetOpen((prev) => !prev);
  }, []);

  return (
    <Container isSmallScreen={isSmallScreen}>
      <Typography variant='body' color={colors.text.main}>
        {`${selectedTimesheets.length} timesheet/s selected`}
      </Typography>
      <ActionBtnsWrap isSmallScreen={isSmallScreen}>
        <Button
          variant='contained'
          onClick={toggleApproveTsOpen}
          style={{
            backgroundColor: colors.surfaceAccent.default,
            color: colors.textAccent.inverse,
            padding: isSmallScreen ? '6px 10px' : '6px 16px'
          }}
        >
          Approve All
        </Button>
        <Button
          variant='text'
          onClick={() => {
            table.resetRowSelection();
          }}
          style={{
            backgroundColor: 'transparent',
            color: colors.text.main,
            padding: isSmallScreen ? '6px 10px' : '6px 16px'
          }}
        >
          {isSmallScreen ? 'Clear' : 'Clear Selection'}
        </Button>
      </ActionBtnsWrap>
      {approveTimesheetOpen && (
        <BulkApproveTimesheetModal
          resetSelection={handleResetSelection}
          timesheets={selectedTimesheets}
          isOpen={approveTimesheetOpen}
          toggleOpen={toggleApproveTsOpen}
          approveStatus={approveStatus}
          sendToLabel={sendToLabel}
          onApprove={onApprove}
        />
      )}
    </Container>
  );
}

const Container = styled('div')<{ isSmallScreen: boolean }>`
  background: ${({ theme }) => theme.colors.surfaceInteraction.selected};
  border-radius: 4px;
  padding: ${({ isSmallScreen }) => (isSmallScreen ? '6px' : '0 12px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isSmallScreen }) => (isSmallScreen ? '16px' : '22px')};
`;

const ActionBtnsWrap = styled('div')<{ isSmallScreen: boolean }>`
  background: ${({ theme }) => theme.colors.surfaceInteraction.selected};
  border-radius: 4px;
  padding: ${({ isSmallScreen }) => (isSmallScreen ? '6px' : '12px')};
  display: flex;
  align-items: center;
  gap: 5px;
`;
