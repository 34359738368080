import { Box, Chip, styled, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { useParams } from 'react-router';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { Typography } from '../../../components/Typography/Typography';
import { useTimesheetStatusColor } from '../../../hooks/useTimesheetStatusColor';
import { TimesheetStatus, TimesheetStatusLabel } from '../../../types';
import { useMonthlySingleTimesheet } from '../../../queries/hooks/useMonthlySingleTimesheet';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { getAccountantTsStatusLabel } from '../../../utils/tsMappers';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';
import { TimesheetNavigation } from '../../AccountantSingleTimesheet/components/TimesheetNavigation';

export function SingleTimesheetHeader() {
  const { colors } = useTheme();
  const { id } = useParams();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const statusLabel = useMemo(() => {
    if (!singleTimesheet) return;
    return getAccountantTsStatusLabel(singleTimesheet?.status as TimesheetStatus);
  }, [singleTimesheet]);

  const statusColor = useTimesheetStatusColor(
    singleTimesheet?.status as unknown as TimesheetStatusLabel
  );

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <Container isSmallScreen={isSmallScreen}>
      <UserInfoWrap isSmallScreen={isSmallScreen}>
        <AvatarCell
          name={singleTimesheet?.user?.name || ''}
          avatarBoxStyle={{
            width: 34,
            height: 34,
            minWidth: 34,
            maxWidth: 34,
            background: colors.surfaceIndicator.needsReview,
          }}
          avatarLetterStyle={{
            color: colors.text.main,
            fontSize: 14,
          }}
        />
        <Box display={'flex'} gap={'8px'} flex={1}>
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={statusColor}>
                {statusLabel}
              </Typography>
            }
          />
        </Box>
      </UserInfoWrap>
      <TimesheetNavigation timesheet={singleTimesheet} />
    </Container>
  );
}

const Container = styled('div')<{ isSmallScreen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: ${({ isSmallScreen }) => (isSmallScreen ? '6px' : '28px')};
`;

const UserInfoWrap = styled('div')<{ isSmallScreen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${({ isSmallScreen }) => (isSmallScreen ? '100%' : '35%')};
  gap: ${({ isSmallScreen }) => (isSmallScreen ? '8px' : '20px')};
`;
