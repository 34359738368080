import { styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as FileIcon } from '../../assets/icons/file-blue.svg';
import { Typography } from '../Typography/Typography';

const DropArea = styled('div')<{ isDragActive: boolean }>`
  background-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.colors.surfaceBackground.bg2 : theme.colors.surfaceBackground.highlighted};
  border: 1px dashed
    ${({ theme, isDragActive }) =>
      isDragActive ? theme.colors.border.accent : theme.colors.border.default};
  border-radius: 4px;
  padding: 16px;
  margin: 10px 0;
  min-height: 90px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.surfaceBackground.bg2};
    border: 1px dashed ${({ theme }) => theme.colors.border.accent};
  }
`;

const LabelWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
}
export const UploadFileArea: FC<Props> = ({ onDrop }) => {
  const { colors } = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} accept='.xlsx' />
      <FileIcon />
      <LabelWrapper>
        <Typography variant='subtitle1' color={colors.text.main}>
          Drag and drop or
        </Typography>
        <Typography variant='subtitle1' color={colors.textAccent.default}>
          Choose Excel File to Upload
        </Typography>
      </LabelWrapper>
      <Typography variant='srOnly' color={colors.text.caption}>
        {`Supported format: Excel (.xlsx)`}
      </Typography>
    </DropArea>
  );
};
