import { Button, useTheme, Box, styled } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TableV1 } from '../../../components/Table/TableV1';
import { useIcons } from '../../../hooks/useIcons';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { isSmallScreenState, userState } from '../../../state/UIState';
import { TableContentSkeletonLoader } from '../../AccountantTimesheetStatuses/components/TableContentSkeletonLoader';
import { useOPTsStatusesColumns } from '../hooks/useOPTsStatusesColumns';
import { useMonthlyTimesheets } from '../../../queries/hooks/useMonthlyTimesheets';
import { mapTsStatuses } from '../../../utils/tsMappers';
import { SelectItem, UserRole } from '../../../types';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Typography } from '../../../components/Typography/Typography';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { ExportMultiTimesheetModal } from './ExportTimesheetsModal';

const useNavigateParams = () => {
  const navigate = useNavigate();

  return (pathname, params) => {
    const path = {
      pathname,
      search: createSearchParams(params).toString(),
    };
    navigate(path);
  };
};

export function OPTimesheetStatusesTable() {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const location = useLocation();
  const { DownLoadIcon } = useIcons();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');
  const user = useAtomValue(userState);
  const navigateWithParams = useNavigateParams();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState<SelectItem | null>(null);
  const [dateFilterValue, setDateFilterValue] = useState(yearQueryParam);

  const currentYear = new Date().getUTCFullYear();

  const { isLoading: timesheetsLoading, data: timesheets } = useMonthlyTimesheets({
    userId: user?.id as number,
    yearQueryParam: dateFilterValue ? dateFilterValue : currentYear.toString(),
  });

  const statusMappedData = useMemo(() => {
    if (!timesheets) return [];
    const OPTimesheets = timesheets.filter((timesheet) => timesheet.user?.id === user?.id);
    return mapTsStatuses(OPTimesheets, UserRole.OPERATING_PARTNER);
  }, [timesheets, user?.id]);

  const columns = useOPTsStatusesColumns();

  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const table = useReactTable({
    data: statusMappedData ?? [],
    columns,
    state: {
      rowSelection,
      sorting,
      columnFilters,
    },
    onRowSelectionChange: setRowSelection,
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  const onExportModalOpen = useCallback(() => {
    if (user?.id) {
      setExportModalOpen(true);
      searchParams.set('ids', user.id.toString());
      setSearchParams(searchParams);
    } 
  }, [searchParams, user?.id, setSearchParams]);

  const sortedStatusList = useMemo(
    () =>
      Array.from(table.getColumn('status')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value) => ({ id: value, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, timesheets]
  );

  const onChangeStatusFilter = useCallback(
    (status: SelectItem | null) => {
      setSelectedStatus(status);
      table.getColumn('status')?.setFilterValue(status?.id ?? '');
    },
    [setSelectedStatus, table]
  );

  const onChangeDateFilter = useCallback(
    (date: Date | null) => {
      if (date) {
        const year = date.getFullYear().toString();
        setDateFilterValue(year);
        navigateWithParams(location.pathname, { year });
      } else {
        setDateFilterValue('');
        navigate(location.pathname);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDateFilterValue]
  );

  const isResetDisabled = useMemo(() => {
    if (dateFilterValue || selectedStatus) return false;
    return true;
  }, [dateFilterValue, selectedStatus]);

  const onFiltersReset = useCallback(() => {
    setDateFilterValue(null);
    setSelectedStatus(null);
    table.getColumn('status')?.setFilterValue('');
  }, [table]);

    const onExportModalClose = useCallback(() => {
      setExportModalOpen(false);
      searchParams.delete('ids');
      setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

  return (
    <div>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Container isSmallScreen={isSmallScreen}>
          <Box flexGrow={isSmallScreen ? '1' : '0'}>
            <DatePicker
              clearable
              selectedDate={dateFilterValue}
              setSelectedDate={onChangeDateFilter}
              disableFuture
              views={['year']}
              inputFormat='yyyy'
              placeholder={isSmallScreen ? 'Year' : 'Select Year'}
            />
          </Box>
          <SingleSelect
            style={{ width: isSmallScreen ? '150px' : '200px', marginRight: '14px' }}
            listboxStyle={{ height: '195px' }}
            options={sortedStatusList as SelectItem[]}
            value={selectedStatus}
            onChange={(_, status) => onChangeStatusFilter(status)}
            disablePortal
            fieldPlaceholder={isSmallScreen ? 'Status' : 'Timesheet Status'}
          />
          <Box flexGrow={isSmallScreen ? '1' : '0'}>
            <Button
              onClick={onFiltersReset}
              variant='text'
              style={{ height: '28px', marginTop: '2px' }}
              disabled={isResetDisabled}
              sx={{
                '.MuiButton-startIcon': { marginRight: '2px' },
                '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
              }}
            >
              <Typography variant='subtitle1' color={colors.textAccent.default}>
                {isSmallScreen ? 'Reset' : 'Reset Filters'}
              </Typography>
            </Button>
          </Box>
        </Container>
        <Button
          variant='outlined'
          startIcon={<DownLoadIcon />}
          style={{
            backgroundColor: colors.surfaceBackground.bg1,
            marginBottom: isSmallScreen ? '10px' : '0px',
          }}
          onClick={onExportModalOpen}
        >
          Export
        </Button>
      </Box>
      {timesheetsLoading ? (
        <TableContentSkeletonLoader />
      ) : (
        <TableV1
          table={table}
          height='auto'
          isLastColumnSticky={false}
          disableRowHover
          wrapperStyle={{
            maxHeight: 'calc(100vh - 288px)',
            overflowX: 'scroll',
            border: `1px solid ${colors.border.default}`,
            overflowY: 'scroll',
          }}
        />
      )}
      {exportModalOpen && (
        <ExportMultiTimesheetModal isOpen={exportModalOpen} onClose={onExportModalClose} />
      )}
    </div>
  );
}

const Container = styled('div')<{ isSmallScreen: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isSmallScreen }) => (isSmallScreen ? '10px' : '20px')};
  gap: ${({ isSmallScreen }) => (isSmallScreen ? '0px' : '12px')};
`;
