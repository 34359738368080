import { Box, Button, styled, useTheme } from '@mui/material';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { Typography } from '../../../components/Typography/Typography';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';
import { tsStatuesSelectedOPs } from '../../../state/UIAccountantState';
import { SelectItem, UserWithTsPeriodData } from '../../../types';
import { isSmallScreenState } from '../../../state/UIState';

type Props = {
  columnFilters: ColumnFiltersState;
  table: Table<UserWithTsPeriodData>;
};

const useNavigateParams = () => {
  const navigate = useNavigate();

  return (pathname, params) => {
    const path = {
      pathname,
      search: createSearchParams(params).toString(),
    };
    navigate(path);
  };
};

export function FilterSection({ table, columnFilters }: Props) {
  const { colors } = useTheme();
  const location = useLocation();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const navigateWithParams = useNavigateParams();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const yearQueryParam = params.get('year');

  const [dateFilterValue, setDateFilterValue] = useState(yearQueryParam);
  const [selectedOPs, setSelectedOPs] = useAtom(tsStatuesSelectedOPs);

  const { data: operatingPartners } = useAllUserOperatingPartners(yearQueryParam || '');

  const sortedOPList = useMemo(
    () => {
      if (!operatingPartners) return [];
      const allOPs = operatingPartners.sort().map((user) => ({ id: user.id, value: user.name }));
      return allOPs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, operatingPartners]
  );

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('name')?.setFilterValue(OPs);
      }
    },
    [table, setSelectedOPs]
  );

  const onChangeDateFilter = useCallback(
    (date: Date | null) => {
      if (date) {
        const year = date.getFullYear().toString();
        setDateFilterValue(year);
        navigateWithParams(location.pathname, { year });
      } else {
        setDateFilterValue('');
        navigate(location.pathname);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDateFilterValue]
  );

  const onFiltersReset = useCallback(() => {
    setSelectedOPs([]);
    setDateFilterValue(null);
    navigate(location.pathname);
  }, [setDateFilterValue, setSelectedOPs, navigate, location]);

  const isResetDisabled = useMemo(() => {
    const selectedOPs = columnFilters.find((filter) => filter.id === 'name')?.value as SelectItem[];
    if (selectedOPs?.length === 0 && !yearQueryParam) return true;

    return false;
  }, [columnFilters, yearQueryParam]);

  useEffect(() => {
    table.getColumn('name')?.setFilterValue(selectedOPs);
  }, [selectedOPs, table]);

  return (
    <Container isSmallScreen={isSmallScreen}>
      <Box flexGrow={isSmallScreen ? '1' : '0'}>
        <Multiselect
          style={{
            width: isSmallScreen ? 'auto' : '200px',
            marginRight: isSmallScreen ? '4px' : '14px',
          }}
          fieldPlaceholder={isSmallScreen ? 'OP' : 'Select OP'}
          options={sortedOPList as SelectItem[]}
          onChange={(_, OPs) => onChangeOPFilter(OPs)}
          value={selectedOPs}
        />
      </Box>
      <Box flexGrow={isSmallScreen ? '1' : '0'}>
        <DatePicker
          clearable
          selectedDate={dateFilterValue}
          setSelectedDate={onChangeDateFilter}
          disableFuture
          views={['year']}
          inputFormat='yyyy'
          placeholder={isSmallScreen ? 'Year' : 'Select Year'}
        />
      </Box>
      <Box flexGrow={isSmallScreen ? '1' : '0'}>
        <Button
          onClick={onFiltersReset}
          variant='text'
          style={{ height: '28px', marginTop: '2px' }}
          disabled={isResetDisabled}
          sx={{
            '.MuiButton-startIcon': { marginRight: '2px' },
            '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          }}
        >
          <Typography variant='subtitle1' color={colors.textAccent.default}>
            {isSmallScreen ? 'Reset' : 'Reset Filters'}
          </Typography>
        </Button>
      </Box>
    </Container>
  );
}

const Container = styled('div')<{ isSmallScreen: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isSmallScreen }) => (isSmallScreen ? '10px' : '20px')};
  gap: ${({ isSmallScreen }) => (isSmallScreen ? '0px' : '12px')};
`;
