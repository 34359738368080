import { ColorTokens } from '../structure';
import { colorPalette } from '../colors';

export const colors: ColorTokens = {
  surfaceBackground: {
    bg1: colorPalette.basic.bgDarkGradient,
    bg2: colorPalette.greyDark[80],
    bg3: colorPalette.greyDark[50],
    bg4: '#2C2C3E',
    highlighted: '#273055',
    overlay: colorPalette.basic.overlayDark,
  },
  surfaceAccent: {
    default: colorPalette.blueDark[50],
    hover: colorPalette.blueDark[70],
    pressed: colorPalette.blueDark[80],
    disabled: colorPalette.greyDark[70],
  },
  surfaceStatus: {
    warning: '#222947',
    active: '#222947',
    noAction: '#222947',
    error: '#222947',
    success: '#222947',
    warningLight: colorPalette.greyDark[70],
    activeLight: colorPalette.greyDark[70],
    noActionLight: colorPalette.greyDark[70],
    errorLight: colorPalette.greyDark[70],
    successLight: colorPalette.greyDark[70],
  },
  surfaceInteraction: {
    hover: colorPalette.primary[5],
    hoverGrey: colorPalette.grey[1],
    pressed: colorPalette.blueDark[60],
    disabled: colorPalette.grey[5],
    selected: colorPalette.blueDark[60],
    selectedLight: '#323448',
  },
  surfaceIndicator: {
    needsReview: '#068EFE',
    error: '#FF4040',
    needsDetails: '#DDBA01',
    closed: '#323448',
    completed: '#439C3D',
  },
  text: {
    main: colorPalette.greyDark[5],
    secondary: colorPalette.greyDark[20],
    caption: colorPalette.greyDark[40],
  },
  textAccent: {
    default: colorPalette.blueDark[50],
    hover: colorPalette.blueDark[70],
    pressed: colorPalette.blueDark[80],
    inverse: colorPalette.greyDark[5],
    disabled: colorPalette.greyDark[50],
    pressedOnSolidBg: colorPalette.greyDark[5],
  },
  textStatus: {
    warning: colorPalette.yellowDark[70],
    active: colorPalette.blueDark[50],
    noAction: colorPalette.greyDark[60],
    error: colorPalette.redDark[20],
    success: colorPalette.greenDark[20],
  },
  icon: {
    default: colorPalette.grey[50],
    defaultControls: colorPalette.grey[30],
    accent: colorPalette.blueDark[50],
    hover: colorPalette.blueDark[70],
    pressed: colorPalette.blueDark[80],
    inverse: colorPalette.basic.white,
    disabled: colorPalette.grey[30],
    disabledLight: colorPalette.grey[10],
  },
  iconStatus: {
    warning: colorPalette.gold[60],
    active: colorPalette.blueDark[50],
    noAction: colorPalette.greyDark[60],
    error: colorPalette.redDark[50],
    success: colorPalette.greenDark[50],
  },
  border: {
    default: colorPalette.greyDark[60],
    hover: colorPalette.greyDark[50],
    accent: colorPalette.blueDark[50],
  },
  skeletonLoader: {
    background: '#323448',
    foreground: '#3f425c',
    linearProgressBackground: colorPalette.greyDark[60],
    linearProgressForeground: colorPalette.blueDark[60],
  },
};
