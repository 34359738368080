import { ROUTES } from '../../../constants/routes';
import { ReactComponent as MyTimesheetsIcon } from '../../../assets/icons/my-timesheets.svg';
import { ReactComponent as TimerIcon } from '../../../assets/icons/timer.svg';
import { ReactComponent as AdministrationIcon } from '../../../assets/icons/administration.svg';
import { User } from '../../../types';
import { canSeeConfig } from '../../../constants/constants';

export const getMenuItems = (user: User | null) => {
  const userRoleKey = user?.role as keyof typeof canSeeConfig;

  return [
    // op
    {
      icon: <MyTimesheetsIcon />,
      route: `/${ROUTES.PENDING_TIMESHEETS}`,
      id: ROUTES.PENDING_TIMESHEETS,
      title: 'My Timesheets',
      show:
        canSeeConfig[userRoleKey]?.pages.includes(ROUTES.PENDING_TIMESHEETS) ||
        (user?.isOp as boolean),
      children: [
        {
          route: `/${ROUTES.PENDING_TIMESHEETS}`,
          id: `${ROUTES.PENDING_TIMESHEETS}`,
          title: 'Pending Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.ALL_TIMESHEETS}`,
          id: `${ROUTES.ALL_TIMESHEETS}`,
          title: 'All Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.TIMESHEETS_SUMMARY}`,
          id: `${ROUTES.TIMESHEETS_SUMMARY}`,
          title: 'Timesheets Summary',
          show: true,
        },
      ],
    },
    // proxy
    {
      icon: <TimerIcon />,
      route: `/${ROUTES.ISSUED_TIMESHEETS}`,
      id: ROUTES.ISSUED_TIMESHEETS,
      title: 'Timesheets',
      show: canSeeConfig[userRoleKey]?.pages.includes(ROUTES.ISSUED_TIMESHEETS),
      children: [
        {
          route: `/${ROUTES.ISSUED_TIMESHEETS}`,
          id: `${ROUTES.ISSUED_TIMESHEETS}`,
          title: 'Issued Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.ALL_TIMESHEETS}`,
          id: `${ROUTES.ALL_TIMESHEETS}`,
          title: 'All Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.PROXY_TIMESHEETS_SUMMARY}`,
          id: `${ROUTES.PROXY_TIMESHEETS_SUMMARY}`,
          title: 'Timesheets Summary',
          show: true,
        },
      ],
    },
    //op manager
    {
      icon: <TimerIcon />,
      route: `/${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`,
      id: ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET,
      title: 'OP Timesheets',
      show: canSeeConfig[userRoleKey]?.pages.includes(ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET),
      children: [
        {
          route: `/${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`,
          id: `${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`,
          title: 'Submitted Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`,
          id: `${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`,
          title: 'All Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS}`,
          id: `${ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS}`,
          title: 'All Operating Partners',
          show: true,
        },
        {
          route: `/${ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY}`,
          id: `${ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY}`,
          title: 'Timesheets Summary',
          show: true,
        },
      ],
    },
    //
    //deal owner
    {
      icon: <TimerIcon />,
      route: `/${ROUTES.DEAL_OWNER_PENDING_TIMESHEETS}`,
      id: ROUTES.DEAL_OWNER_PENDING_TIMESHEETS,
      title: 'Timesheets',
      show: canSeeConfig[userRoleKey]?.pages.includes(ROUTES.DEAL_OWNER_PENDING_TIMESHEETS),
      children: [
        {
          route: `/${ROUTES.DEAL_OWNER_PENDING_TIMESHEETS}`,
          id: `${ROUTES.DEAL_OWNER_PENDING_TIMESHEETS}`,
          title: 'Pending Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`,
          id: `${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`,
          title: 'All Timesheets',
          show: true,
        },
        {
          route: `/${ROUTES.DEAL_OWNER_DEALS}`,
          id: `${ROUTES.DEAL_OWNER_DEALS}`,
          title: 'All Deals',
          show: true,
        },
      ],
    },
    //accountant
    {
      icon: <TimerIcon />,
      route: `/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}`,
      id: ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES,
      title: 'Timesheets',
      show: canSeeConfig[userRoleKey]?.pages.includes(ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES),
      children: [
        {
          route: `/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}`,
          id: `${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}`,
          title: 'Timesheets statuses',
          show: true,
        },
        {
          route: `/${ROUTES.ACCOUNTANT_OP_OVERVIEW}`,
          id: `${ROUTES.ACCOUNTANT_OP_OVERVIEW}`,
          title: 'OPs Overview',
          show: true,
        },
      ],
    },
    // admin permissions
    {
      icon: <AdministrationIcon />,
      route: `/${ROUTES.TIMESHEETS_ASSIGNMENT}`,
      id: ROUTES.TIMESHEETS_ASSIGNMENT,
      title: 'Administration',
      show: user?.isAdmin as boolean,
      children: [
        {
          route: `/${ROUTES.TIMESHEETS_ASSIGNMENT}`,
          id: `${ROUTES.TIMESHEETS_ASSIGNMENT}`,
          title: 'Timesheet Assignment',
          show: true,
        },
        {
          route: `/${ROUTES.PROXY_ASSIGNMENT}`,
          id: `${ROUTES.PROXY_ASSIGNMENT}`,
          title: 'Proxy Assignment',
          show: true,
        },
        {
          route: `/${ROUTES.DEALS}`,
          id: `${ROUTES.DEALS}`,
          title: 'Deals',
          show: true,
        },
        {
          route: `/${ROUTES.USER_ADMINISTRATION}`,
          id: `${ROUTES.USER_ADMINISTRATION}`,
          title: 'User Administration',
          show: true,
        },
        {
          route: `/${ROUTES.EVENT_SCHEDULE}`,
          id: `${ROUTES.EVENT_SCHEDULE}`,
          title: 'Event Schedule',
          show: true,
        },
      ],
    },
    //
  ];
};
