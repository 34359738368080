import { atom } from 'jotai';
import { ExtendedToastProps, LoadingId, THEME, User } from '../types';

//general ui state
export const loadingIdsState = atom<LoadingId[]>([]);
export const sidebarMenuPinnedState = atom(false);
export const isOpenToastMessageState = atom(false);
export const configToastMessageState = atom<ExtendedToastProps | null>(null);
export const selectedThemeState = atom<THEME | null>(null);
export const isSmallScreenState = atom<boolean>(false);

//user state
export const userState = atom<User | null>(null);
export const isUserLoadingState = atom<boolean>(true);
