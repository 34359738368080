import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { capitalizeWords } from '../../../utils/formatters';
import { Holiday } from '../../../types';

const generateExcelTemplate = (worksheet: Worksheet) => {
  worksheet.columns = [
    { header: 'Name', key: 'name', width: 30 },
    { header: 'Country (US, UK)', key: 'country', width: 30 },
    { header: 'Type', key: 'type', width: 30 },
    { header: 'Start Date (YYYY-MM-DD)', key: 'startDate', width: 30 },
    { header: 'End Date - optional (YYYY-MM-DD)', key: 'endDate', width: 30 },
  ];

  worksheet.mergeCells('A1:E1');
  const countryCell = worksheet.getCell('A1');
  countryCell.value =
    'Important: Values for Country can be "US", "UK" or "US, UK".';
  countryCell.alignment = { vertical: 'middle', wrapText: true };
  countryCell.font = { bold: true, size: 12 };

  worksheet.mergeCells('A2:E2');
  const mergedCell = worksheet.getCell('A2');
  mergedCell.value =
    'Important: Values for Type can be "Bank Holiday", "Duvet Day", "Offsite Event", "Company Event", "General Restructuring" or "Other".';
  mergedCell.alignment = { vertical: 'middle', wrapText: true };
  mergedCell.font = { bold: true, size: 12 };

  worksheet.addRow([]);
  worksheet.mergeCells('A3:E3');

  worksheet.addRow([
    'Name',
    'Country (US, UK)',
    'Type',
    'Start Date (YYYY-MM-DD)',
    'End Date - optional (YYYY-MM-DD)',
  ]);
}

export const downloadTemplate = async () => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Template');
  generateExcelTemplate(worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, 'Events_Template.xlsx');
};

export const exportHolidays = async (holidays: Holiday[]) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Events');
  generateExcelTemplate(worksheet);

  holidays.forEach((holiday) => {
    const startDate = new Date(holiday.startDate);
    const endDate = holiday.endDate ? new Date(holiday.endDate) : null;

    const row = worksheet.addRow([
      capitalizeWords(holiday.name),
      holiday.country.toUpperCase(),
      capitalizeWords(holiday.type),
      startDate,
      endDate,
    ]);

    row.getCell(4).numFmt = 'yyyy-mm-dd';
    row.getCell(5).numFmt = 'yyyy-mm-dd';
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `Events_Export_${holidays[0].year}.xlsx`);
};
