import { styled, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ROUTES } from '../../constants/routes';
import { useMonthlySingleTimesheet } from '../../queries/hooks/useMonthlySingleTimesheet';
import { getMonthLabel } from '../../utils/formatters';
import { ContentWrapper } from '../layout/styled';
import { useAtomValue } from 'jotai';
import { isSmallScreenState, userState } from '../../state/UIState';
import { TimesheetSummaryTable } from './components/TimesheetSummaryTable';
import { UserRole } from '../../types';

export function SingleTimesheetSummary() {
  const { id } = useParams();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const user = useAtomValue(userState);

  const { colors } = useTheme();
  const { data: singleTimesheet } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const breadcrumbTitle = singleTimesheet
    ? `${getMonthLabel(singleTimesheet.month)}, ${singleTimesheet.year}`
    : '';

  return (
    <PageWrapper isSmallScreen={isSmallScreen}>
      <Wrapper isSmallScreen={isSmallScreen}>
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Timesheet summary',
              url: user?.role === UserRole.PROXY ? `/${ROUTES.PROXY_TIMESHEETS_SUMMARY}` : `/${ROUTES.TIMESHEETS_SUMMARY}`,
              color: colors.textAccent.default,
            },
            { title: breadcrumbTitle || '' },
          ]}
        />
      </Wrapper>
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <TimesheetSummaryTable />
      </ContentWrapper>
    </PageWrapper>
  );
}
const Wrapper = styled('div')<{isSmallScreen: boolean}>`
  padding: ${({isSmallScreen}) => isSmallScreen ? '8px 16px' : '28px 36px 0'}
`;

const PageWrapper = styled('div')<{isSmallScreen: boolean}>`
  padding-top: ${({isSmallScreen}) => isSmallScreen ? '48px' : '0px'}
`;
