import { useAtomValue } from 'jotai';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AllTimesheetsTable } from './components/AllTimesheetsTable';
import { isSmallScreenState } from '../../state/UIState';

export const AllTimesheets = () => {
  const isSmallScreen = useAtomValue(isSmallScreenState);
  
  return (
    <>
      <PageHeader
        title='All Timesheets'
        description=''
        breadcrumbs={[{ title: 'All Timesheets' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <AllTimesheetsTable />
      </ContentWrapper>
    </>
  );
};
