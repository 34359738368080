import { useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ROUTES } from '../../constants/routes';
import { isSmallScreenState, userState } from '../../state/UIState';
import { UserRole } from '../../types';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { IssuedTimesheetsTable } from './components/IssuedTimesheetsTable';

export const IssuedTimesheets = () => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const user = useAtomValue(userState);
  const pageTitle =
    user?.role === UserRole.OPERATING_PARTNER ? 'Pending Timesheets' : 'Issued Timesheets';

  return (
    <>
      <PageHeader
        title={pageTitle}
        description=''
        breadcrumbs={[
          {
            title: 'All timesheet',
            url: `/${ROUTES.ALL_TIMESHEETS}`,
            color: colors.textAccent.default,
          },
          {
            title: pageTitle,
            // url: `/${ROUTES.ISSUED_TIMESHEETS}`,
          },
        ]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <IssuedTimesheetsTable />
      </ContentWrapper>
    </>
  );
};
