import { styled, useTheme } from '@mui/material';
import React from 'react';
import { Typography } from '../Typography/Typography';
import { Breadcrumb } from '../../types';
import { useNavigate } from 'react-router';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

const BreadcrumbWrapper = styled('div')<{isSmallScreen: boolean}>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: ${({isSmallScreen}) => isSmallScreen ? '0px' : '24px'};
`;

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <BreadcrumbWrapper isSmallScreen={isSmallScreen}>
      {breadcrumbs.map((breadcrumb, index) => {
        const url = breadcrumb?.url;
        const textColor = breadcrumb?.color;
        return (
          <Typography
            key={index}
            variant='body'
            style={{
              cursor: url ? 'pointer' : undefined,
            }}
            color={textColor || colors.text.caption}
            onClick={() => {
              if (!url) return;
              navigate(url);
            }}
          >
            {index > 0 && ' / '}
            {breadcrumb.title}
          </Typography>
        );
      })}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumbs;
