import { useState } from 'react';
import { Tabs, Tab, Box, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMonthlySingleTimesheet } from '../../../queries/hooks/useMonthlySingleTimesheet';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { AccountantTSView as TimesheetTab } from '../../../types';
import { DownloadCSVLink } from '../../AccountantSingleTimesheet/components/DownloadCSVButton';
import { AccountantSingleTimesheetWeekdayTable } from '../../AccountantSingleTimesheet/components/AccountantSingleTimesheetWeekdayTable';
import { AccountantSingleTimesheetWeekTable } from '../../AccountantSingleTimesheet/components/AccountantSingleTimesheetWeekTable';
import { SingleTimesheetHeader } from './TimesheetSummaryHeader';

export function TimesheetSummaryTable() {
  const { colors } = useTheme();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TimesheetTab.weekday);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const handleTabChange = (_: React.SyntheticEvent, newValue: TimesheetTab) => {
    setSelectedTab(newValue);
  };

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab
            label='By Weekday'
            value={TimesheetTab.weekday}
            sx={{
              color: colors.text.main,
              textTransform: 'none',
            }}
          />
          <Tab
            label='By Week'
            value={TimesheetTab.week}
            sx={{
              color: colors.text.main,
              textTransform: 'none',
            }}
          />
        </Tabs>
        <DownloadCSVLink selectedTab={selectedTab} timesheet={singleTimesheet} showEmptyDeals={true} />
      </Box>
      {selectedTab === TimesheetTab.weekday && (
        <AccountantSingleTimesheetWeekdayTable
          weeklyTimesheet={singleTimesheet.weeklyTimesheets}
          timesheetRows={singleTimesheet.timesheetRows}
        />
      )}
      {selectedTab === TimesheetTab.week && (
        <AccountantSingleTimesheetWeekTable weeklyTimesheet={singleTimesheet.weeklyTimesheets} />
      )}
    </>
  );
}
