import { ButtonBase, styled, useTheme } from '@mui/material';
import { MainMenuItemProps } from '../../types';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { Typography } from '../../components/Typography/Typography';

export interface Props {
  item: MainMenuItemProps;
  isExpanded: boolean;
  isSmallScreen?: boolean;
}

const Wrapper = styled('div')<{ isExpanded: boolean, isSmallScreen: boolean }>`
  width: ${({ isExpanded, isSmallScreen }) => (isSmallScreen ? '100%' : isExpanded ? '216px' : '64px')};
  padding-left: 8px;
  padding-right: ${({ isSmallScreen }) => (isSmallScreen ? '8px' : '0px')};
`;

const MenuSubItemWrapper = styled('div')<{ isActive: boolean }>`
  cursor: pointer;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-left: 8px;
  ${({ isActive, theme }) =>
    isActive ? `background-color: ${theme.colors.surfaceInteraction.selectedLight}` : ''};
`;

const SubMenuWrapper = styled('div')`
  padding: 8px 0 0 36px;
`;

export interface ButtonProps {
  isActive?: boolean;
  disableHover?: boolean;
  isExpanded?: boolean;
  isSmallScreen?: boolean;
}

const IconWrapper = styled('div')<ButtonProps>`
  min-height: 48px;
  width: ${({ isExpanded, isSmallScreen }) => (isSmallScreen ? '98%' : isExpanded ? '208px' : '48px')};
  border-radius: 4px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.surfaceInteraction.selectedLight : 'transparent'};
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  gap: 12px;
  svg: {
    g {
      g {
        path {
          fill: ${({ isActive, theme }) =>
            isActive ? theme.colors.icon.accent : theme.colors.icon.default};
        }
      }
    }
  }
`;

const SvgWrapper = styled('div')``;

export const MenuItem: FC<Props> = ({ item, isExpanded, isSmallScreen = false }) => {
  const location = useLocation();
  const activeRoute = useMemo(() => location.pathname.split('/')[1], [location.pathname]);
  const navigate = useNavigate();
  const { colors } = useTheme();

  const isMainActive = item.children.some((child) => child.id === activeRoute);
  const onClick = (route: string) => {
    navigate(route);
  };

  if (!item.show) return null;

  return (
    <Wrapper key={item.route} isExpanded={isExpanded} isSmallScreen={isSmallScreen}>
      <ButtonBase TouchRippleProps={{ color: 'white' }} style={{ borderRadius: '4px', width: (isSmallScreen ? '100%' : isExpanded ? '208px' : '48px') }}>
        <IconWrapper
          isActive={isMainActive}
          onClick={() => onClick(item.route)}
          isExpanded={isExpanded}
          isSmallScreen={isSmallScreen}
        >
          <SvgWrapper
            sx={{
              svg: {
                path: { fill: isMainActive ? colors.icon.accent : colors.icon.default },
              },
              marginBottom: '-2px',
            }}
          >
            {item.icon}
          </SvgWrapper>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0 }}
            >
              <Typography
                variant='subtitle2'
                color={colors.text.main}
                style={{ marginBottom: '-2px' }}
              >
                {item.title}
              </Typography>
            </motion.div>
          )}
        </IconWrapper>
      </ButtonBase>

      {isMainActive && isExpanded && (
        <motion.div
          initial={{ height: '0' }}
          animate={{ height: `${item.children.length * 36 + 8}px` }}
          transition={{ duration: 0.3 }}
          exit={{ height: '0' }}
          layout
        >
          <SubMenuWrapper>
            {item.children.map((subItem) => (
              <motion.div
                key={subItem.route}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                exit={{ opacity: 0 }}
                layout
              >
                <MenuSubItemWrapper
                  isActive={isExpanded && activeRoute === subItem.id}
                  onClick={() => onClick(subItem.route)}
                >
                  <Typography variant='subtitle1' color={colors.text.main}>
                    {subItem.title}
                  </Typography>
                </MenuSubItemWrapper>
              </motion.div>
            ))}
          </SubMenuWrapper>
        </motion.div>
      )}
    </Wrapper>
  );
};
