import { useTheme } from '@mui/material';
import { TableV1 } from '../../../components/Table/TableV1';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useOpManagerTimesheetsSummaryColumns } from '../hooks/useOpManagerTimesheetsSummaryColumns';
import { OPSubTable } from './OPSubTable';
import { useOpManagerTimesheetsSummaryData } from '../../../queries/hooks/useOPManagerTimesheetsSummary';
import { OpManagerSummaryRowData } from '../../../types';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';

interface Props {
  selectedYear: number;
}

export const OpManagerTimesheetsSummaryTable = ({ selectedYear }: Props) => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isLoading } = useOpManagerTimesheetsSummaryData(selectedYear);

  const columns = useOpManagerTimesheetsSummaryColumns({ nestedTable: false });

  const table = useReactTable({
    data: (data as OpManagerSummaryRowData[]) ?? [],
    columns,
    state: {
      sorting,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowCanExpand: (row) => !!row.original?.ops?.length,
    getExpandedRowModel: getExpandedRowModel(),
    meta: (row) => {
      return {
        isExpanded: row.original.ops,
      };
    },
  });

  if (isLoading) return <TableSkeletonLoader />;

  return (
    <div>
      <TableV1
        table={table}
        height='auto'
        isLastColumnSticky={false}
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
        subComponent={OPSubTable}
      />
    </div>
  );
};
