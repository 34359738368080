import { useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { MontlyTimesheetsBulkActions } from '../../../components/MontlyTimesheetsBulkActions/MontlyTimesheetsBulkActions';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { TableV1 } from '../../../components/Table/TableV1';
import { useMonthlyTimesheets } from '../../../queries/hooks/useMonthlyTimesheets';
import { userState } from '../../../state/UIState';
import { MonthlyTimesheet, TimesheetStatus, UserRole } from '../../../types';
import { getIsPendingDealOwnerStatus } from '../../../utils/getIsPendingDealOwnerTimesheetStatus';
import { useAllTimesheetsTableColumns } from '../hooks/useAllTimesheetsTableColumns';
import { FilterSection } from './FilterSection';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { mapTsStatuses } from '../../../utils/tsMappers';

export function AllTimesheeetsTable() {
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  const { isLoading: timesheetsLoading, data: timesheets } = useMonthlyTimesheets({
    userId: user?.id as number,
  });

  const statusMappedData = useMemo(() => {
    if (!timesheets) return [];
    return mapTsStatuses<MonthlyTimesheet>(timesheets, UserRole.DEAL_OWNER);
  }, [timesheets]);

  const columns = useAllTimesheetsTableColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: statusMappedData ?? [],
    columns,
    state: {
      rowSelection,
      sorting,
      columnFilters,
    },
    enableRowSelection: (row) => !!getIsPendingDealOwnerStatus(row.original, user), // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  if (timesheetsLoading || !timesheets) return <TableSkeletonLoader />;

  const hasSelectedItems = !!Object.keys(rowSelection)?.length;

  return (
    <div>
      {hasSelectedItems && (
        <MontlyTimesheetsBulkActions
          table={table}
          approveStatus={TimesheetStatus.CLOSED}
          onApprove={() => navigate(`/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`)}
        />
      )}
      <FilterSection table={table} columnFilters={columnFilters} />
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </div>
  );
}
