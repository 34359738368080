import { capitalizeWords } from './formatters';

export const validateHolidayExcelData = (excelRows: any[]) => {
  const validCountries = ['uk', 'us', 'us,uk', 'uk,us'];
  const validTypes = [
    'Bank Holiday',
    'Duvet Day',
    'Offsite Event',
    'Company Event',
    'General Restructuring',
    'Other',
  ];

  const filteredRows = excelRows
    .filter((row) => {
      const isValidName = typeof row.name === 'string' && row.name.trim() !== '';

      const normalizedCountry = row.country?.toLowerCase().replace(/\s+/g, '');
      const isValidCountry = validCountries.includes(normalizedCountry);

      const normalizedType = row.type?.toLowerCase().replace(/\s+/g, ' ');
      const isValidType = validTypes.some(
        (validType) => validType.toLowerCase() === normalizedType
      );

      const isValidStartDate = row.startDate instanceof Date;
      const isValidEndDate =
        !row.endDate ||
        (row.endDate instanceof Date &&
          !isSameDate(row.startDate, row.endDate) &&
          row.endDate > row.startDate);

      return isValidName && isValidCountry && isValidType && isValidStartDate && isValidEndDate;
    })
    .map((row) => ({
      name: capitalizeWords(row.name),
      country: row.country.toUpperCase(),
      type: capitalizeWords(row.type),
      startDate: row.startDate,
      endDate: row.endDate,
    }));

  return {
    filteredData: filteredRows,
    hasErrors: filteredRows.length !== excelRows.length,
  };
};

function isSameDate(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
