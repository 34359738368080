import { ColorTokens } from '../structure';
import { colorPalette } from '../colors';

export const colors: ColorTokens = {
  surfaceBackground: {
    bg1: colorPalette.basic.white,
    bg2: colorPalette.grey[1],
    bg3: colorPalette.grey[5],
    bg4: colorPalette.grey[5],
    highlighted: colorPalette.primary[10],
    overlay: colorPalette.basic.overlayLight,
  },
  surfaceAccent: {
    default: colorPalette.primary[50],
    hover: colorPalette.primary[70],
    pressed: colorPalette.primary[80],
    disabled: colorPalette.grey[5],
  },
  surfaceStatus: {
    warning: colorPalette.gold[5],
    active: colorPalette.primary[5],
    noAction: colorPalette.grey[5],
    error: colorPalette.red[5],
    success: colorPalette.green[5],
    warningLight: colorPalette.gold[1],
    activeLight: colorPalette.primary[1],
    noActionLight: colorPalette.grey[1],
    errorLight: colorPalette.red[1],
    successLight: colorPalette.green[1],
  },
  surfaceInteraction: {
    hover: colorPalette.primary[5],
    hoverGrey: colorPalette.grey[1],
    pressed: colorPalette.primary[10],
    disabled: colorPalette.grey[5],
    selected: colorPalette.primary[10],
    selectedLight: colorPalette.primary[5],
  },
  surfaceIndicator: {
    needsReview: '#99D0FE',
    error: '#FF4040',
    needsDetails: '#FFE454',
    closed: '#EAEAEF',
    completed: '#C5F6C1',
  },
  text: {
    main: colorPalette.grey[70],
    secondary: colorPalette.grey[60],
    caption: colorPalette.grey[40],
  },
  textAccent: {
    default: colorPalette.primary[60],
    hover: colorPalette.primary[70],
    pressed: colorPalette.primary[70],
    inverse: colorPalette.basic.white,
    disabled: colorPalette.grey[30],
    pressedOnSolidBg: colorPalette.primary[60],
  },
  textStatus: {
    warning: colorPalette.gold[60],
    active: colorPalette.primary[50],
    noAction: colorPalette.grey[60],
    error: colorPalette.red[50],
    success: colorPalette.green[60],
  },
  icon: {
    default: colorPalette.grey[50],
    defaultControls: colorPalette.grey[30],
    accent: colorPalette.primary[50],
    hover: colorPalette.primary[70],
    pressed: colorPalette.primary[70],
    inverse: colorPalette.basic.white,
    disabled: colorPalette.grey[30],
    disabledLight: colorPalette.grey[10],
  },
  iconStatus: {
    warning: colorPalette.gold[60],
    active: colorPalette.primary[50],
    noAction: colorPalette.grey[10],
    error: colorPalette.red[40],
    success: colorPalette.green[60],
  },
  border: {
    default: colorPalette.grey[10],
    hover: colorPalette.grey[20],
    accent: colorPalette.primary[30],
  },
  skeletonLoader: {
    background: colorPalette.primary[5],
    foreground: colorPalette.primary[10],
    linearProgressBackground: colorPalette.primary[30],
    linearProgressForeground: colorPalette.primary[50],
  },
};
