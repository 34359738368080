import { Modal, styled } from '@mui/material';
import { ModalHeader } from './ModalHeader';
import { ModalActionBtns } from './ModalActions';
import { ActionButtonsProps } from '../../types';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: JSX.Element;
  width?: string;
  top?: string;
  actionButtonsProps: ActionButtonsProps;
};

export const ModalComponent = ({
  isOpen,
  onClose,
  title,
  width,
  top,
  actionButtonsProps,
  children,
}: Props) => {
  const isSmallDevice = useAtomValue(isSmallScreenState);
  
  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Wrapper width={width} top={top} tabIndex={-1} isSmallDevice={isSmallDevice}>
        <ModalHeader onClose={onClose} title={title} />
        <ModalContent>{children}</ModalContent>
        <ModalActionBtns
          onSubmit={actionButtonsProps?.onSubmit}
          onCancel={onClose}
          isSubmitDisabled={actionButtonsProps?.isSubmitDisabled}
          cancelText={actionButtonsProps?.cancelText}
          submitText={actionButtonsProps?.submitText}
          cancelBtnStyle={actionButtonsProps?.cancelBtnStyle}
          submitBtnStyle={actionButtonsProps?.submitBtnStyle}
        />
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled('div')<{ width?: string; top?: string; isSmallDevice: boolean }>`
  width: ${({ width, isSmallDevice }) => isSmallDevice ? '95%' : width || '560px'};
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  border-radius: 8px;
  position: absolute;
  top: ${({ top }) => top || '50px'};
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ isSmallDevice }) => isSmallDevice ? '12px' : '24px'};
  gap: ${({ isSmallDevice }) => isSmallDevice ? '12px' : '24px'};
`;

const ModalContent = styled('div')`
  color: ${({ theme }) => theme.colors.text.main};
`;
