import { useAtomValue } from 'jotai';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AssigmentUserTable } from './components/AssigmentUserTable';
import { isSmallScreenState } from '../../state/UIState';

export const TimesheetAssignment = () => {
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='Timesheet Assignment'
        description=''
        breadcrumbs={[{ title: 'Administration' }, { title: 'Timesheet Assignment' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <AssigmentUserTable />
      </ContentWrapper>
    </>
  );
};
