import { Workbook } from 'exceljs';
import { AccountantTSView, MonthlyTimesheet, TimesheetRow, WeeklyTimesheet } from '../../../types';
import {
  addDaysToDate,
  formatUSDate,
  getDayLabel,
  getMonthLabelShort,
  getTodaysDate,
} from '../../../utils/formatters';
import { saveAs } from 'file-saver';
import {
  calcTotalPerDay,
  calcTotalPerWeekday,
  calcTotalWeekdayPerDeal,
  calcWeekdayTotal,
} from './useTimesheetCalculations';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const getDateLabel = (dayOrder: number, weekStart: string) => {
  if (weekStart) {
    const date = addDaysToDate(weekStart, dayOrder - 1);
    const formattedDate = formatUSDate(date.toISOString());
    return `${getDayLabel(dayOrder).slice(0, 3)} ${formattedDate}`;
  }
  return '';
};

const rowAvg = (timesheet: TimesheetRow, weekDatesLength: number, holidaysLength: number) => {
  if (
    !(
      timesheet.monday +
      timesheet.tuesday +
      timesheet.wednesday +
      timesheet.thursday +
      timesheet.friday
    )
  )
    return 0;
  return (
    (timesheet.monday +
      timesheet.tuesday +
      timesheet.wednesday +
      timesheet.thursday +
      timesheet.friday) /
    (weekDatesLength + holidaysLength)
  );
};

function calcTotal(weeklyTimesheet: WeeklyTimesheet) {
  return (
    weeklyTimesheet.timesheetRows.reduce(
      (acc, row) =>
        acc + rowAvg(row, weeklyTimesheet.weekDates.length, weeklyTimesheet.holidays.length),
      0
    ) + '%'
  );
}

export const timesheetExcelTemplate = async (
  monthlyTS: MonthlyTimesheet,
  selectedTab: AccountantTSView
) => {
  const title = selectedTab === AccountantTSView.week ? 'Timesheet_by_Week' : 'Timesheet_by_Weekday';
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  worksheet.columns = [
    { header: 'OP', key: 'op', width: 20 },
    { header: 'Period', key: 'period', width: 20 },
    { header: 'View', key: 'view', width: 20 },
    { header: 'Date Generated', key: 'date', width: 20 },
    { header: '', key: '1', width: 20 },
    { header: '', key: '2', width: 20 },
    { header: '', key: '3', width: 20 },
  ];
  worksheet.addRow([
    monthlyTS.user?.name,
    `${getMonthLabelShort(monthlyTS.month)} ${monthlyTS.year}`,
    title,
    getTodaysDate(),
  ]);

  if (selectedTab === AccountantTSView.week) {
    monthlyTS.weeklyTimesheets.flat().forEach((weeklyTS, i) => {
      worksheet.addRow(['']);
      worksheet.addRow([`Week ${i + 1}`]);
      worksheet.addRow([
        'Deal',
        ...weekdays.map((_, index) => `${getDateLabel(index + 1, weeklyTS.weekStart)}`),
        'Total per Deal',
      ]);

      weeklyTS.timesheetRows
        .filter(
          (row) =>
            row.monday > 0 ||
            row.tuesday > 0 ||
            row.wednesday > 0 ||
            row.thursday > 0 ||
            row.friday > 0
        )
        .forEach((row) => {
          const rows = worksheet.addRow([
            row.name,
            ...weekdays.map((weekday) =>
              typeof row[`${weekday.toLowerCase()}`] === 'number'
                ? row[`${weekday.toLowerCase()}`] + '%'
                : '0%'
            ),
            rowAvg(row, weeklyTS.weekDates.length, weeklyTS.holidays.length) + '%',
          ]);
          [
            rows.getCell(2),
            rows.getCell(3),
            rows.getCell(4),
            rows.getCell(5),
            rows.getCell(6),
            rows.getCell(7),
          ].forEach((cell) => {
            cell.alignment = { horizontal: 'right' };
          });
        });
      const row = worksheet.addRow([
        'Total per day',
        ...calcTotalPerDay(weeklyTS),
        calcTotal(weeklyTS),
      ]);
      [
        row.getCell(2),
        row.getCell(3),
        row.getCell(4),
        row.getCell(5),
        row.getCell(6),
        row.getCell(7),
      ].forEach((cell) => {
        cell.alignment = { horizontal: 'right' };
      });
    });
  }

  if (selectedTab === AccountantTSView.weekday) {
    worksheet.addRow([]);
    worksheet.addRow(['Deal', ...weekdays, 'Total per Deal']);
    monthlyTS.timesheetRows
      .sort((a, b) => b.dealId - a.dealId)
      .filter(
        (row, i) =>
          calcTotalWeekdayPerDeal('monday', monthlyTS.weeklyTimesheets, row.dealId, row.name) > 0 ||
          calcTotalWeekdayPerDeal('tuesday', monthlyTS.weeklyTimesheets, row.dealId, row.name) >
            0 ||
          calcTotalWeekdayPerDeal('wednesday', monthlyTS.weeklyTimesheets, row.dealId, row.name) >
            0 ||
          calcTotalWeekdayPerDeal('thursday', monthlyTS.weeklyTimesheets, row.dealId, row.name) >
            0 ||
          calcTotalWeekdayPerDeal('friday', monthlyTS.weeklyTimesheets, row.dealId, row.name)
      )
      .forEach((row, i) => {
        console.log(row);
        const rows = worksheet.addRow([
          row.name,
          calcTotalWeekdayPerDeal('monday', monthlyTS.weeklyTimesheets, row.dealId, row.name) + '%',
          calcTotalWeekdayPerDeal('tuesday', monthlyTS.weeklyTimesheets, row.dealId, row.name) +
            '%',
          calcTotalWeekdayPerDeal('wednesday', monthlyTS.weeklyTimesheets, row.dealId, row.name) +
            '%',
          calcTotalWeekdayPerDeal('thursday', monthlyTS.weeklyTimesheets, row.dealId, row.name) +
            '%',
          calcTotalWeekdayPerDeal('friday', monthlyTS.weeklyTimesheets, row.dealId, row.name) + '%',
          row.avg.toFixed(2) + '%',
        ]);
        [
          rows.getCell(2),
          rows.getCell(3),
          rows.getCell(4),
          rows.getCell(5),
          rows.getCell(6),
          rows.getCell(7),
        ].forEach((cell) => {
          cell.alignment = { horizontal: 'right' };
        });
      });

    const row = worksheet.addRow([
      'Total per day',
      calcTotalPerWeekday('monday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('tuesday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('wednesday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('thursday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('friday', monthlyTS.weeklyTimesheets) + '%',
      calcWeekdayTotal(monthlyTS.weeklyTimesheets) + '%',
    ]);
    [
      row.getCell(2),
      row.getCell(3),
      row.getCell(4),
      row.getCell(5),
      row.getCell(6),
      row.getCell(7),
    ].forEach((cell) => {
      cell.alignment = { horizontal: 'right' };
    });
    worksheet.addRow([]);
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `${title}_${monthlyTS.user?.name}.xlsx`);
};
