import { useState } from 'react';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { UsersTable } from './components/UsersTable';
import { Button, Tab, Tabs, useTheme } from '@mui/material';
import { OPTable } from './components/OPTable';
import { ReactComponent as AddIcon } from '../../assets/icons/add-white.svg';
import { Typography } from '../../components/Typography/Typography';
import { UserModal } from './components/UserModal/UserModal';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

enum UserTabs {
  all = 'all',
  OP = 'OP',
}

export const UserAdministration = () => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const [selectedTab, setSelectedTab] = useState<UserTabs>(UserTabs.all);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const handleTabChange = (_: React.SyntheticEvent, newValue: UserTabs) => {
    setSelectedTab(newValue);
  };

  const tabStyle = {
    color: colors.text.main,
    textTransform: 'none',
    fontSize: '14px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  };

  return (
    <>
      <PageHeader
        title='User Administration'
        breadcrumbs={[{ title: 'Administration' }, { title: 'User Administration' }]}
        actionComponent={
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            style={{ height: '40px' }}
            onClick={() => setAddUserModalOpen(true)}
          >
            <Typography
              variant='h4'
              color={colors.textAccent.inverse}
              style={{ marginBottom: '-1px' }}
            >
              Add a User
            </Typography>
          </Button>
        }
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            marginBottom: '1rem',
          }}
        >
          <Tab label='All Users' value={UserTabs.all} sx={tabStyle} />
          <Tab label='Operating Partner' value={UserTabs.OP} sx={tabStyle} />
        </Tabs>
        {selectedTab === UserTabs.all && <UsersTable />}
        {selectedTab === UserTabs.OP && <OPTable />}
      </ContentWrapper>
      {addUserModalOpen && (
        <UserModal
          userProfileOpen={addUserModalOpen}
          handleUserProfilClose={() => setAddUserModalOpen(false)}
        />
      )}
    </>
  );
};
