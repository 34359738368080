import { Box, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as ReportIcon } from '../../../../assets/icons/report.svg';
import TooltipComponent from '../../../../components/TooltipComponent/TooltipComponent';
import { useCallback, useState } from 'react';
import { Column, Table } from '@tanstack/react-table';
import { TimesheetRow } from '../../../../types';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../../state/UIState';

function TotalCell(props: { table: Table<TimesheetRow>; column: Column<TimesheetRow> }) {
  const { colors } = useTheme();
  // const isOnboarding = id === 'demo';
  // const isAccountant = location.pathname.includes(ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES);
  const accessor = props.column.id;
  const isSmallScreen = useAtomValue(isSmallScreenState);

  // const { data: singleTimesheet } = useSingleTimesheet({
  //   id: Number(id),
  //   enabled: !isOnboarding && !isAccountant,
  // });

  const [errorTooltipOpen, setErrorTooltipOpen] = useState(false);
  const handleErrorTooltipOpen = useCallback(() => {
    setErrorTooltipOpen(true);
  }, []);

  const handleErrorTooltipClose = useCallback(() => {
    setErrorTooltipOpen(false);
  }, []);

  // const shouldDisableDay = props.table
  //   .getFilteredRowModel()
  //   .rows.some((row) => row.getValue(accessor) === null);

  const total = props.table
    .getFilteredRowModel()
    .rows.reduce((total, row) => total + Number(row.getValue(accessor)), 0);

  const error = total > 100;

  // const emptyLabel = useMemo(() => {
  //   if (singleTimesheet) {
  //     if (singleTimesheet?.weekDates.length !== 5 && singleTimesheet?.holidays.length !== 0) {
  //       return 'N/A';
  //     }
  //     return '';
  //   }
  //   return '';
  // }, [singleTimesheet]);
  
  return (
    // <FormField label={''} error={error}>
    <Box display={'flex'} gap={'5px'} alignItems={'center'} paddingLeft={isSmallScreen ? '5px' : '0px'}>
      <Typography variant='body' color={error ? colors.iconStatus.error : colors.text.main}>
        {total + '%'}
      </Typography>
      {error && (
        <TooltipComponent
          open={!!errorTooltipOpen}
          onOpen={handleErrorTooltipOpen}
          onClose={handleErrorTooltipClose}
          content={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',

                gap: 1,
              }}
            >
              <Typography
                variant='body'
                color={colors.text.main}
                style={{
                  textWrap: 'wrap',
                }}
              >
                Input exceeds 100%. Please correct the data.
              </Typography>
            </Box>
          }
        >
          <ReportIcon
            onClick={handleErrorTooltipOpen}
            style={{
              cursor: 'pointer',
            }}
          />
        </TooltipComponent>
      )}
    </Box>
  );
}

export default TotalCell;
