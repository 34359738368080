import { Workbook } from 'exceljs';
import { getMonthLabelShort } from '../../../utils/formatters';
import { saveAs } from 'file-saver';
import { OpManagerSummaryRowData } from '../../../types';

const monthKeys = Array.from({ length: 12 }, (_, i) => i + 1);

export const timesheetsSummaryExcelTemplate = async (
  timesheetsSummary: OpManagerSummaryRowData[],
  year: number
) => {
  const title = 'Timesheets_Summary';
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  worksheet.columns = [{ header: '', key: 'deal', width: 20 }];

  worksheet.addRow([]);
  worksheet.addRow(['Deal', ...monthKeys.map((month) => `${getMonthLabelShort(month)} ${year}`)]);
  timesheetsSummary.forEach((row) => {
    const rows = worksheet.addRow([
      row.dealName,
      ...monthKeys.map((month) =>
        typeof row[month] === 'number' ? row[month]?.toFixed(0) + '%' : '-'
      ),
    ]);
    rows.getCell(1).font = { bold: true };
    monthKeys
      .map((month) => rows.getCell(month + 1))
      .forEach((cell) => {
        cell.alignment = { horizontal: 'right' };
      });

    row.ops?.forEach((opRow) => {
      const opRows = worksheet.addRow([
        opRow.userName,
        ...monthKeys.map((month) =>
          typeof opRow[month] === 'number' ? opRow[month]?.toFixed(0) + '%' : '-'
        ),
      ]);
      monthKeys
      .map((month) => opRows.getCell(month + 1))
      .forEach((cell) => {
        cell.alignment = { horizontal: 'right' };
      });
    });
    worksheet.addRow([]);
  });

  worksheet.addRow([]);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `${title}.xlsx`);
};
