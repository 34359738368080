import { useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { TableV1 } from '../../../components/Table/TableV1';
import { useTimesheets } from '../../../queries/hooks/useTimesheets';
import { userState } from '../../../state/UIState';
import { UserRole } from '../../../types';
import { mapTsStatuses } from '../../../utils/tsMappers';
import { useOpAllTimesheetsTableColumns } from '../hooks/useOpAllTimesheetsTableColumns';
import { useProxyAllTimesheetsTableColumns } from '../hooks/useProxyAllTimesheetsTableColumns';
import { FilterSection } from './FilterSection';

export function AllTimesheetsTable() {
  const { colors } = useTheme();
  const user = useAtomValue(userState);

  const { isLoading: areAllTimesheetsLoading, data: allTimesheets } = useTimesheets({
    userId: user?.id,
  });

  const statusMappedData = useMemo(() => {
    if (!allTimesheets) return [];
    return mapTsStatuses(allTimesheets, UserRole.OPERATING_PARTNER);
  }, [allTimesheets]);

  const useTableColumns = (() => {
    switch (user?.role) {
      case UserRole.PROXY:
        return useProxyAllTimesheetsTableColumns;
      case UserRole.OPERATING_PARTNER:
        return useOpAllTimesheetsTableColumns;
      default:
        return useOpAllTimesheetsTableColumns;
    }
  })();

  const columns = useTableColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: statusMappedData ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  if (areAllTimesheetsLoading || !allTimesheets) return <TableSkeletonLoader />;

  return (
    <div>
      <FilterSection table={table} columnFilters={columnFilters} />
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </div>
  );
}
