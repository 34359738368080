import { IconButton, styled, TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-grey.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { FC, useCallback, useState, useRef, useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { isSmallScreenState, selectedThemeState } from '../../state/UIState';
import { THEME } from '../../types';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { dateToUTCDateOnly } from '../../utils/formatters';

interface Props {
  selectedDate: string | null;
  setSelectedDate: (newDate: Date | null) => void;
  clearable?: boolean;
  placement?: 'bottom-start' | 'bottom-end' | 'bottom';
  disablePast?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
  minDate?: string | null;
  maxDate?: string | null;
  views?: CalendarPickerView[];
  inputFormat?: string;
  placeholder?: string;
}

const Wrapper = styled('div')<{ isActive: boolean; clearable: boolean; isSmallScreen: boolean }>`
  position: relative;
  .custom-date-picker {
    width: 100%;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.border.accent : theme.colors.surfaceBackground.highlighted};
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.border.accent : theme.colors.border.default};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.border.default};
    }
    .MuiInputBase-root {
      height: 36px;
      width: ${({ clearable, isSmallScreen }) =>
        isSmallScreen ? '140px' : clearable ? '170px' : 'auto'};
      padding-left: 0;
      padding-right: 0;
    }
    .MuiMonthPicker-root {
      width: auto;
      margin: 0;
    }
    .MuiInputBase-input {
      font-family: ${({ theme }) => theme.text.font.basierRegular};
      line-height: 18px;
      margin-left: -10px;
      color: ${({ theme }) => theme.colors.text.main};
      caret-color: transparent;
    }
    .MuiButtonBase-root {
      padding: 6px;
      margin: 6px;
    }
    svg {
      margin-top: -2px;
    }
  }
  .MuiInputBase-root.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.surfaceAccent.disabled};
  }
  .MuiInputBase-root.Mui-disabled input {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.textAccent.disabled};
  }
`;

export const DatePicker: FC<Props> = ({
  selectedDate,
  setSelectedDate,
  clearable = false,
  placement = 'bottom',
  disablePast = false,
  disableFuture = false,
  disabled = false,
  minDate,
  maxDate = new Date().toISOString(),
  views = ['year', 'month', 'day'],
  inputFormat = window.navigator.language === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy',
  placeholder = window.navigator.language === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
}) => {
  const [isActive] = useState(false);
  const [selectedTheme] = useAtom(selectedThemeState);
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const isYearOnlyView =
    !views.includes('day') && !views.includes('month') && views.includes('year');
  const isUSFormat = window.navigator.language === 'en-US' || inputFormat.startsWith('MM');

  // Manual input tracking
  const [manualInput, setManualInput] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Helper: Parse date string as a "local date" (ignores timezones)
  const parseDate = (dateStr: string | null) => {
    if (!dateStr || isNaN(new Date(dateStr).getTime())) return null; // Check for null or invalid date
    const isoDate = new Date(dateStr).toISOString();
    const [year, month, day] = isoDate.split('T')[0].split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  // Initialize manual input when selected date changes
  useEffect(() => {
    if (!isEditing && selectedDate) {
      const date = parseDate(selectedDate);
      if (date) {
        if (isYearOnlyView) {
          setManualInput(date.getFullYear().toString());
        } else {
          // Format based on locale
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const year = date.getFullYear().toString();

          setManualInput(isUSFormat ? `${month}/${day}/${year}` : `${day}/${month}/${year}`);
        }
      }
    }
  }, [selectedDate, isEditing, isYearOnlyView, isUSFormat]);

  const selectedValue = useCallback(
    (date: string | null) => {
      if (!date) return null;
      if (views.includes('day')) {
        return parseDate(selectedDate);
      }
      if (isYearOnlyView) {
        // For year-only view
        return new Date(Number(date), 0, 10);
      }
      return selectedDate;
    },
    [selectedDate, views, isYearOnlyView]
  );

  // Parse manual input string to Date
  const parseManualInput = (input: string): Date | null => {
    if (isYearOnlyView) {
      // Year only
      if (input.length === 4 && /^\d{4}$/.test(input)) {
        const year = parseInt(input, 10);
        return new Date(year, 0, 1);
      }
      return null;
    }

    // Full date
    // Remove any non-digit or non-separator characters
    const cleaned = input.replace(/[^\d\\/]/g, '');

    // Check if we have a valid format
    if (isUSFormat) {
      // MM/DD/YYYY
      const match = cleaned.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      if (match) {
        const month = parseInt(match[1], 10) - 1; // 0-based month
        const day = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);
        const date = new Date(year, month, day);

        // Validate date is real
        if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
          return date;
        }
      }
    } else {
      // DD/MM/YYYY
      const match = cleaned.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      if (match) {
        const day = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1; // 0-based month
        const year = parseInt(match[3], 10);
        const date = new Date(year, month, day);

        // Validate date is real
        if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
          return date;
        }
      }
    }

    return null;
  };

  // Format input as user types to enforce date pattern
  const formatDateInput = (input: string): string => {
    if (isYearOnlyView) {
      // Just limit to 4 digits for year
      return input.replace(/\D/g, '').slice(0, 4);
    }

    // Remove any non-digits
    const digits = input.replace(/\D/g, '');

    // Format according to pattern
    if (digits.length <= 2) {
      return digits;
    } else if (digits.length <= 4) {
      return `${digits.slice(0, 2)}/${digits.slice(2)}`;
    } else {
      return `${digits.slice(0, 2)}/${digits.slice(2, 4)}/${digits.slice(4, 8)}`;
    }
  };

  // Handle direct text input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIsEditing(true);

    // Format the input
    const formatted = formatDateInput(value);
    setManualInput(formatted);

    // Check if we have a complete date to set
    const date = parseManualInput(formatted);
    if (date) {
      setSelectedDate(date);
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);

    // Revert to existing date format if input is invalid
    if (!parseManualInput(manualInput) && selectedDate) {
      const date = parseDate(selectedDate);
      if (date) {
        if (isYearOnlyView) {
          setManualInput(date.getFullYear().toString());
        } else {
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const year = date.getFullYear().toString();

          setManualInput(isUSFormat ? `${month}/${day}/${year}` : `${day}/${month}/${year}`);
        }
      }
    }
  };

  // Focus handler
  const handleInputFocus = () => {
    setIsEditing(true);
  };

  return (
    <Wrapper clearable={clearable} isActive={isActive} isSmallScreen={isSmallScreen}>
      <MuiDatePicker
        value={selectedValue(selectedDate)}
        views={views}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={dateToUTCDateOnly(minDate ?? '') || undefined}
        maxDate={dateToUTCDateOnly(maxDate ?? '') || undefined}
        disabled={disabled}
        onChange={(newValue) => {
          if (newValue === null) {
            setSelectedDate(null as unknown as Date);
            setManualInput('');
            return;
          }
          if (newValue.toString() === 'Invalid Date') {
            return;
          }
          if (isNaN(newValue.getTime())) {
            return;
          }
          setSelectedDate(newValue as unknown as Date);
          setIsEditing(false);
        }}
        renderInput={(params) => (
          <TextField
            autoComplete='off'
            {...params}
            inputRef={inputRef}
            inputProps={{
              ...params.inputProps,
              placeholder: isYearOnlyView ? 'Select Year' : placeholder,
              // Override value with our controlled input
              value: manualInput,
              // Override change and key handlers
              onChange: handleInputChange,
              onFocus: handleInputFocus,
              onBlur: handleInputBlur,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment:
                clearable && selectedDate ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedDate(null);
                      setManualInput('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  params?.InputProps?.endAdornment
                ),
            }}
          />
        )}
        InputProps={{ placeholder: isYearOnlyView ? 'Select Year' : placeholder }}
        PopperProps={{
          className: `custom-datepicker-popup custom-datepicker-popup-${
            selectedTheme === THEME.DARK ? selectedTheme : THEME.LIGHT
          }`,
          placement: placement,
          disablePortal: true,
          style: { transform: 'translateY(35px)', position: 'absolute' },
        }}
        openTo={views.includes('day') ? 'day' : views[0]}
        components={{
          OpenPickerIcon: isActive ? CalendarIcon : CalendarIcon,
        }}
        inputFormat={isYearOnlyView ? 'yyyy' : inputFormat}
      />
    </Wrapper>
  );
};
