import { UserRole } from '../types';
import { ROUTES } from './routes';

export const EMPTY_STRING = '';
export const ZERO_ID = 0;
export const STANDARD_STRUCTURED_CAPITAL_INVESTMENT_ALLOCATION_METHOD_ID = 26;
export const DIRECT_SOURCING_TYPE_ID = 1;
export const NO_SUCH_A_SUBORDER_MSG = 'No such a suborder';

const ADMIN_ROUTES = [
  ROUTES.DEALS,
  ROUTES.PROXY_ASSIGNMENT,
  ROUTES.TIMESHEETS_ASSIGNMENT,
  ROUTES.USER_ADMINISTRATION,
  ROUTES.EVENT_SCHEDULE,
];

export const canSeeConfig = {
  [UserRole.ACCOUNTANT]: {
    pages: [ROUTES.ACCOUNTANT_OP_OVERVIEW, ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES],
    adminPages: [
      ROUTES.ACCOUNTANT_OP_OVERVIEW,
      ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES,
      ...ADMIN_ROUTES,
    ],
    defaultPageUrl: `${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}`,
  },
  [UserRole.DEAL_OWNER]: {
    pages: [
      ROUTES.DEAL_OWNER_DEALS,
      ROUTES.DEAL_OWNER_ALL_TIMESHEETS,
      ROUTES.DEAL_OWNER_PENDING_TIMESHEETS,
    ],
    adminPages: [
      ROUTES.DEAL_OWNER_DEALS,
      ROUTES.DEAL_OWNER_ALL_TIMESHEETS,
      ROUTES.DEAL_OWNER_PENDING_TIMESHEETS,
      ...ADMIN_ROUTES,
    ],
    defaultPageUrl: `${ROUTES.DEAL_OWNER_PENDING_TIMESHEETS}`,
  },
  [UserRole.OPERATING_PARTNER]: {
    pages: [ROUTES.ALL_TIMESHEETS, ROUTES.PENDING_TIMESHEETS, ROUTES.TIMESHEETS_SUMMARY],
    adminPages: [ROUTES.ALL_TIMESHEETS, ROUTES.PENDING_TIMESHEETS, ROUTES.TIMESHEETS_SUMMARY, ...ADMIN_ROUTES],
    defaultPageUrl: `${ROUTES.PENDING_TIMESHEETS}`,
  },
  [UserRole.OP_MANAGER]: {
    pages: [
      ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS,
      ROUTES.OP_MANAGER_ALL_TIMESHEETS,
      ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET,
      ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY
    ],
    OPPages: [
      ROUTES.ALL_TIMESHEETS,
      ROUTES.PENDING_TIMESHEETS,
      ROUTES.TIMESHEETS_SUMMARY,
      ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS,
      ROUTES.OP_MANAGER_ALL_TIMESHEETS,
      ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET,
      ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY
    ],
    adminPages: [
      ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS,
      ROUTES.OP_MANAGER_ALL_TIMESHEETS,
      ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET,
      ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY,
      ...ADMIN_ROUTES,
    ],
    OPAdminPages: [
      ROUTES.ALL_TIMESHEETS,
      ROUTES.PENDING_TIMESHEETS,
      ROUTES.TIMESHEETS_SUMMARY,
      ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS,
      ROUTES.OP_MANAGER_ALL_TIMESHEETS,
      ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET,
      ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY,
      ...ADMIN_ROUTES,
    ],
    defaultPageUrl: `${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`,
  },
  [UserRole.PROXY]: {
    pages: [ROUTES.ALL_TIMESHEETS, ROUTES.ISSUED_TIMESHEETS, ROUTES.PROXY_TIMESHEETS_SUMMARY],
    adminPages: [ROUTES.ALL_TIMESHEETS, ROUTES.ISSUED_TIMESHEETS, ROUTES.PROXY_TIMESHEETS_SUMMARY, ...ADMIN_ROUTES],
    defaultPageUrl: `${ROUTES.ISSUED_TIMESHEETS}`,
  },
};
