import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { OPTimesheetStatusesTable } from './components/OPTimesheetStatusesTable';


export function OPTimesheetsSummary() {
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='Timesheets Summary'
        description=''
        breadcrumbs={[{ title: 'Timesheets Summary' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <OPTimesheetStatusesTable />
      </ContentWrapper>
    </>
  );
}