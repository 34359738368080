import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { LoadingId, MonthlyTimesheet, SelectItem, WeeklyTimesheet } from '../../../types';
import { styled, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormField } from '../../../components/FormField/FormField';
import { Typography } from '../../../components/Typography/Typography';
import { ROUTES } from '../../../constants/routes';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useSendBackMontlyTimesheet } from '../../../queries/hooks/useSendBackMontlyTimesheet';
import { addDaysToDate, formatWeekName, getMonthLabel } from '../../../utils/formatters';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { TextareaAutosize } from '../../../components/TextareaAutosize/TextareaAutosize';

type Props = {
  timesheet: MonthlyTimesheet;
  toggleOpen: () => void;
  isOpen: boolean;
};

export function SendBackTimesheetModal({ timesheet, isOpen, toggleOpen }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const [selectedWeeks, setSelectedWeeks] = useState<SelectItem[]>([]);
  const [comment, setComment] = useState('');
  const MAX_COMMENT = 300;
  const { startLoading, stopLoading } = useLoadingBar();
  const timesheetName = `${getMonthLabel(timesheet.month)} ${timesheet.year}`;

  const { mutate: onSendBackTimesheet, isLoading: sendBackInProgress } =
    useSendBackMontlyTimesheet();

    const weekStart = useCallback((weeklyTS: WeeklyTimesheet) => {
      if (weeklyTS.weekDates.length) {
        return addDaysToDate(weeklyTS.weekDates[0], 1);
      }
      if (weeklyTS.holidays.length) {
        return addDaysToDate(
          `${weeklyTS.holidays[0].year}-${weeklyTS.holidays[0].month}-${weeklyTS.holidays[0].day}`,
          1
        );
      }
      return new Date();
    }, []);
  
    const issueWeekList: SelectItem[] = useMemo(() => {
      return timesheet.weeklyTimesheets.map((weeklyTS) => ({
        id: weeklyTS.id,
        value: formatWeekName(weekStart(weeklyTS).toISOString(), weeklyTS.weekDates.length + weeklyTS.holidays.length),
      }));
    }, [timesheet.weeklyTimesheets, weekStart]);

  const onSelectIssueWeek = useCallback(
    (weeks: SelectItem[] | undefined) => {
      if (weeks) {
        setSelectedWeeks(weeks);
      }
    },
    [setSelectedWeeks]
  );

  const handleSendBackTimesheet = async () => {
    startLoading(LoadingId.sendBackTimesheet);
    onSendBackTimesheet(
      {
        id: timesheet.id,
        rejectedWeeks: selectedWeeks.map((weeks) => Number(weeks.id)),
        rejectNote: comment,
      },
      {
        onSuccess: () => {
          navigate(`/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`);
          pushSuccessToast({
            title: 'Timesheet Sent Back',
            message: `${timesheet.user?.name} ${timesheetName} timesheet has been sent back to Operating Partner.`,
          });
          toggleOpen();
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to send back timesheet' });
        },
        onSettled: () => {
          stopLoading(LoadingId.sendBackTimesheet);
        },
      }
    );
  };

  const shouldDisableSubmit =
    sendBackInProgress || comment.length > MAX_COMMENT || !selectedWeeks.length;

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={toggleOpen}
      actionButtonsProps={{
        isSubmitDisabled: shouldDisableSubmit,
        onSubmit: handleSendBackTimesheet,
        onCancel: toggleOpen,
        submitText: 'Send Back',
        submitBtnStyle: {
          //   background: deleteInProgress ? undefined : colors.iconStatus.error,
        },
      }}
      title={`Send Back Timesheet`}
      top='80px'
    >
      <>
        <InfoWrapper>
          <Typography variant='body' color={colors.text.main}>
            {`Are you sure you want to send back ${timesheet.user?.name} ${timesheetName} timesheet?`}
          </Typography>
        </InfoWrapper>
        <FormField label={'Issue Week'} error={''}>
          <Multiselect
            fieldPlaceholder='Select a Week'
            options={issueWeekList}
            value={selectedWeeks}
            onChange={(_, weeks) => {
              onSelectIssueWeek(weeks);
            }}
            sx={{
              marginBottom: '1rem',
            }}
          />
        </FormField>
        <FormField label={'Comment'} error={''}>
          <TextareaAutosize
            value={comment}
            onChange={(event) => {
              const value = event.target.value;
              setComment(value);
            }}
            autoComplete='off'
            name={'comment'}
            placeholder={'Please, leave a comment for the Operating Partner'}
            maxRows={5}
          />
        </FormField>
        <Typography
          variant='caption'
          color={colors.textAccent.disabled}
          style={{
            textAlign: 'right',
            marginTop: '2px',
          }}
        >{`${comment.length}/${MAX_COMMENT}`}</Typography>
      </>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;
