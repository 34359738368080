import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { QUERY_KEYS } from '../constants';

async function fetchHolidayYears(): Promise<number[]> {
  const { data } = await Http.axios.get<number[]>('/holiday/years');
  return data.reverse();
}

export const useHolidayYears = () => {
  return useQuery([QUERY_KEYS.HOLIDAY_YEARS], () => fetchHolidayYears(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useInvalidateHolidayYears = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.HOLIDAY_YEARS,
    });
  };
};
