import { Button, useTheme } from '@mui/material';
import { useIcons } from '../../../hooks/useIcons';
import { AccountantTSView as TimesheetTab, MonthlyTimesheet } from '../../../types';
import { timesheetExcelTemplate } from '../hooks/useTimesheetExcelTemplate';

interface DownloadCSVLinkProps {
  selectedTab: TimesheetTab;
  timesheet: MonthlyTimesheet;
  showEmptyDeals?: boolean;
}

export const DownloadCSVLink = ({ selectedTab, timesheet, showEmptyDeals = false }: DownloadCSVLinkProps) => {
  const { colors } = useTheme();
  const { DownLoadIcon } = useIcons();

  return (
    <Button
      variant='outlined'
      startIcon={<DownLoadIcon />}
      style={{
        backgroundColor: colors.surfaceBackground.bg1,
      }}
      onClick={() => timesheetExcelTemplate(timesheet, selectedTab, showEmptyDeals)}
    >
      Export
    </Button>
  );
};
