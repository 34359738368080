import { useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { SelectItem, User } from '../../../types';

import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import DealCell from '../components/DealsCell';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';

const columnHelper = createColumnHelper<User>();

export const useOperatingPartnerTableColumn = () => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <AvatarCell
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
            avatarLetterStyle={{
              color: colors.text.main,
            }}
            name={info.getValue()}
          />
        ),
        header: () => <TableHeaderCell text='Operating Partner' />,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original?.name || '';
          const valueB = rowB.original?.name || '';
          return valueA.localeCompare(valueB);
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some((user) => user.id === row.original.id);
        },
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),

      columnHelper.accessor('operatingPartnerDeals', {
        cell: (info) => <DealCell deals={info.row.original.operatingPartnerDeals} />,
        header: () => <TableHeaderCell text='Deals' />,
        enableSorting: false,
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
          width: isSmallScreen ? '200px' : 'auto',
          minWidth: isSmallScreen ? '200px' : 'auto',
          maxWidth: isSmallScreen ? '200px' : 'auto',
        },
        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;

          const nameMatchExist = row.original?.name
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          const dealsMatchExist = (row.original?.operatingPartnerDeals || []).some((deal) =>
            deal.name.toLowerCase().includes(filterValue.toLowerCase())
          );

          return !!nameMatchExist || !!dealsMatchExist;
        },
      }),
    ],
    [colors.surfaceIndicator.needsReview, colors.text.main, isSmallScreen]
  );

  return columns;
};
