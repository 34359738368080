import { format, isWithinInterval, parseISO } from 'date-fns';

export const formatterDate = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

export function formatDate(dateAsISOString?: string) {
  if (!dateAsISOString) return '';
  return formatterDate.format(new Date(dateAsISOString));
}

export function addDaysToDate(date: string, days: number) {
  const [year, month, day] = date.split('T')[0].split('-').map(Number);

  if (!year || !month || !day) {
    console.error('Invalid date format:', date);
    return new Date(NaN); 
  }

  const result = new Date(year, month - 1, day, 0, 0);
  result.setDate(result.getDate() + days);

  return result;
}

export function getTimesheetDateLabel (weekStart: string, dayOrder: number) {
  if (weekStart) {
    const [year, month, day] = weekStart.split('T')[0].split('-').map(Number);

    if (!year || !month || !day) {
      console.error('Invalid date format:', weekStart);
      return '';
    }

    const result = new Date(year, month - 1, day);
    result.setDate(result.getDate() + dayOrder - 1);

    const formattedYear = result.getFullYear();
    const formattedMonth = String(result.getMonth() + 1).padStart(2, '0');
    const formattedDay = String(result.getDate()).padStart(2, '0');

    return `${getDayLabel(dayOrder).slice(
      0,
      3
    )}, ${formattedMonth}/${formattedDay}/${formattedYear}`;
  }
  return '';
}

const formatterNumberShort = new Intl.NumberFormat(navigator.language, {
  compactDisplay: 'short',
  currency: 'USD',
  notation: 'compact',
});
export function formatNumberShort(value: number) {
  return formatterNumberShort.format(value);
}

const formatterUSDShort = new Intl.NumberFormat(navigator.language, {
  compactDisplay: 'short',
  currency: 'USD',
  notation: 'compact',
  style: 'currency',
});
export function formatUSDShort(value: number) {
  return formatterUSDShort.format(value);
}

export function getTodaysDate() {
  const today = new Date();
  let day: number | string = today.getDate();
  let month: number | string = today.getMonth() + 1;
  const year = today.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return `${day}-${month}-${year}`;
}

export const getMonthLabel = (month: number) => {
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
};

export const getMonthLabelShort = (month: number) => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
    default:
      return '';
  }
};

export const getDayLabel = (day: number) => {
  switch (day) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';
    default:
      return '';
  }
};

export function formatNumber(inputValue, decimalPlaces = 0) {
  if (!inputValue) return '';
  // Remove any non-digit characters (except for a single decimal point)
  const cleanedValue = inputValue.replace(/[^0-9.]/g, '');

  // Split the value into integer and decimal parts
  const parts = cleanedValue.split('.');
  let integerPart = parts[0];
  const decimalPart = parts[1] || '';

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer and decimal parts with a decimal point
  const numberOfPlacesToAdd =
    decimalPlaces - decimalPart.length > 0 ? decimalPlaces - decimalPart.length : 0;
  const formattedValue =
    parts.length === 2 || decimalPlaces > 0
      ? `${integerPart}.${decimalPart}${'0'.repeat(numberOfPlacesToAdd)}`
      : integerPart;

  return formattedValue;
}

export function formatWeekName(weekStart?: string, weekLength?: number) {
  if (!weekStart || !weekLength) return '';

  const startDateParts = weekStart.split('T')[0].split('-').reverse();
  if (startDateParts.length < 3 || isNaN(Number(startDateParts[0])) || isNaN(Number(startDateParts[1])) || isNaN(Number(startDateParts[2]))) {
    return '';
  }

  const startDateFormated = `${
    Number(startDateParts[0]) > 9 ? startDateParts[0] : `0${Number(startDateParts[0])}`
  } ${getMonthLabelShort(Number(startDateParts[1]))} ${startDateParts[2]}`;
  const endDate = addDaysToDate(weekStart, weekLength - 1);

  if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
    console.error('Invalid endDate:', endDate);
    return '';
  }

  const week = `${startDateFormated} - ${formatDate(formatDateToString(endDate))}`;
  return week;
}

export function formatTSWeekFormat(weekStart?: string, weekEnd?: string) {
  if (!weekStart || !weekEnd) return '';
  const weekStartArr = weekStart.split('-');
  const weekStartDay = weekStartArr[2];
  const weekStartMonth = getMonthLabelShort(Number(weekStartArr[1]));

  const weekEndArr = weekEnd.split('-');
  const weekEndDay = weekEndArr[2];
  const weekEndMonth = getMonthLabelShort(Number(weekEndArr[1]));
  const weekEndYear = weekEndArr[0];
  return `${weekStartMonth} ${weekStartDay} - ${weekEndMonth} ${weekEndDay}, ${weekEndYear}`;
}

export function dateToUTCDateOnly(dateStr: string) {
  return new Date(
    Number(dateStr.split('T')[0].split('-')[0]),
    Number(dateStr.split('T')[0].split('-')[1]) - 1,
    Number(dateStr.split('T')[0].split('-')[2])
  );
}

export function formatLongDateRange(inputStartDate: string, inputEndDate?: string | null) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const startDate = inputStartDate.split('T')[0];
  const [year, month, day] = startDate.split('-');

  const startWeekday = daysOfWeek[dateToUTCDateOnly(inputStartDate).getDay()];
  const startDateFormatted = `${month}/${day}/${year}`;

  if (!inputEndDate) {
    return `${startDateFormatted}, ${startWeekday}`;
  }

  const endDate = inputEndDate.split('T')[0];
  const [yearEnd, monthEnd, dayEnd] = endDate.split('-');

  const endWeekday = daysOfWeek[dateToUTCDateOnly(inputEndDate).getDay()];
  const endDateFormatted = `${monthEnd}/${dayEnd}/${yearEnd}`;

  return `${startDateFormatted}-${endDateFormatted}, ${startWeekday}-${endWeekday}`;
}

export function formatShortWeekName(weekStart?: string) {
  if (!weekStart) return '';
  const startDateFormated = formatDate(weekStart).split(',')[0].slice(0, 6);

  const endDate = addDaysToDate(weekStart, 7);
  const endDateFormated = `${endDate.getDate()} ${getMonthLabelShort(
    endDate.getMonth() + 1
  )}, ${endDate.getFullYear()}`;

  const week = `${startDateFormated} - ${endDateFormated}`;

  return week;
}

export function getWeekStatus(weekStart?: string, daysAfterNo = 7) {
  if (!weekStart) return '';
  const today = new Date();

  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);

  const daysAfter = new Date(today);
  daysAfter.setDate(today.getDate() + daysAfterNo);

  const givenDate = addDaysToDate(weekStart, 7);

  const isBetweenDates = isWithinInterval(givenDate, { start: today, end: daysAfter });

  if (isBetweenDates) {
    return 'New';
  } else {
    return 'Overdue';
  }
}

export function getMonthStatus(givenDate?: Date) {
  if (!givenDate) return '';
  const today = new Date();
  // Adjust today's date to remove the time part
  today.setHours(0, 0, 0, 0);

  const monthMatch = givenDate.getMonth() === today.getMonth();
  const yearMatch = givenDate.getFullYear() === today.getFullYear();

  if (monthMatch && yearMatch) {
    return 'New';
  } else {
    return 'Overdue';
  }
}

export function formatShortDate(date: string) {
  if (!date) return '';
  const dateArr = date.split('-');
  const month = parseInt(dateArr[1]);
  const day = parseInt(dateArr[2]);

  return `${getMonthLabelShort(month)} ${day}`;
}

export function formatDateWithTime(date: string) {
  let formattedDate = format(parseISO(date), 'MMM dd, yyyy hh:mmaaa');
  formattedDate = formattedDate.replace(/am|pm/, (match) => match.toUpperCase());
  return formattedDate;
}

export function formatWeeklyTSDate(weekday: number, date: string) {
  const dateArr = date.split('-');
  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];
  if (date && weekday) {
    return `${getDayLabel(weekday)}, ${month}/${day}/${year}`;
  }
  return '';
}

export function formatUSDate(date: string) {
  const dateArr = date.split('-');
  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];
  // bug on BE
  if (date && date !== "'null'") {
    return `${month}/${day?.slice(0, 2)}/${year}`;
  }
  return '';
}

// format number to decimal only if there are already decimals
export function formatDecimalNumber(num: number, digit: number) {
  return num.toFixed(digit).replace(/[.,]00$/, '');
}

export function formatDateToString(date) {
  // Ensure it's a Date object
  if (!(date instanceof Date)) {
    throw new Error('Invalid Date object');
  }

  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  // Return in "YYYY-MM-DD" format
  return `${year}-${month}-${day}`;
}

export const capitalizeWords = (str: string) => {
  return str
    .split(' ') 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
    .join(' ');
};