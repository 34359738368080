import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useCallback, useMemo, useState } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { TableV1 } from '../../../components/Table/TableV1';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { Typography } from '../../../components/Typography/Typography';
import { useDealsTableColumns } from '../hooks/useDealsTableColumns';
import { useDeals } from '../../../queries/hooks/useDeals';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { SelectItem } from '../../../types';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';

const Wrapper = styled('div')``;

const FiltersSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

export const DealsTable = () => {
  const { isLoading: areDealsLoading, data: deals } = useDeals();
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const columns = useDealsTableColumns();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedOPs, setSelectedOPs] = useState<SelectItem[]>([]);

  const table = useReactTable({
    data: deals ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (deal) => String(deal.id),
  });

  const sortedOPs = useMemo(() => {
    if (!deals) return [];
    const allOPs = deals
      .flatMap((deal) => deal.operatingPartners)
      .map((op) => ({
        id: op?.id ?? '',
        value: op?.name ?? '',
      }));

    const uniqueOPs = Array.from(new Map(allOPs.map((item) => [item.id, item])).values());
    return uniqueOPs;
  }, [deals]);

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('operatingPartners')?.setFilterValue(OPs);
      }
    },
    [setSelectedOPs, table]
  );

  const isResetDisabled = useMemo(() => {
    const selectedOPsFilter = columnFilters.find((filter) => filter.id === 'operatingPartners')
      ?.value as SelectItem[];
    const searchFilter = columnFilters.find((filter) => filter.id === 'name')?.value as string;
    if (!searchFilter && (selectedOPsFilter ?? []).length === 0) return true;
    return false;
  }, [columnFilters]);

  const onFiltersReset = useCallback(() => {
    setSelectedOPs([]);
    table.getColumn('name')?.setFilterValue('');
    table.getColumn('operatingPartners')?.setFilterValue('');
  }, [table, setSelectedOPs]);

  if (areDealsLoading || !deals) return <TableSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersSection>
        <FiltersWrapper>
          <SearchInput
            placeholder='Search'
            style={{ width: isSmallScreen ? '150px' : '370px', height: '36px' }}
            onClear={() => table.getColumn('name')?.setFilterValue('')}
            onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
            value={table.getColumn('name')?.getFilterValue() ?? ''}
          />
          <Multiselect
            style={{ width: isSmallScreen ? '150px' : '200px', marginRight: '14px' }}
            fieldPlaceholder={isSmallScreen ? 'OP' : 'Select OP'}
            options={sortedOPs}
            value={selectedOPs}
            onChange={(_, OPs) => onChangeOPFilter(OPs)}
          />
          <Button
            onClick={onFiltersReset}
            variant='text'
            style={{ height: '28px', marginTop: '2px' }}
            disabled={isResetDisabled}
            sx={{
              '.MuiButton-startIcon': { marginRight: '2px' },
              '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
            }}
          >
            <Typography variant='subtitle1' color={colors.textAccent.default}>
              Reset Filters
            </Typography>
          </Button>
        </FiltersWrapper>
      </FiltersSection>
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </Wrapper>
  );
};
