import { Box, Chip, Icon, IconButton, useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as USAFlag } from '../../../assets/icons/usa-flag.svg';
import { ReactComponent as UKFlag } from '../../../assets/icons/uk-flag.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/close-gray.svg';
import { formatLongDateRange } from '../../../utils/formatters';
import { HolidayExcelRow } from '../../../types';

const columnHelper = createColumnHelper<HolidayExcelRow>();

export const useUploadedHolidayTableColumns = (onRemoveHoliday) => {
  const { colors } = useTheme();

  const formatHolidayDate = useCallback((holiday: HolidayExcelRow) => {
    return holiday.endDate
      ? formatLongDateRange(holiday.startDate.toISOString(), holiday.endDate.toISOString())
      : formatLongDateRange(holiday.startDate.toISOString());
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main} style={{whiteSpace: 'unset'}}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Event' />,
        meta: {
          width: '150px',
          minWidth: '150px',
          maxWidth: '150px',
          thStyles: { paddingLeft: '12px', paddingRight: '12px' },
          tdStyles: { paddingLeft: '12px', paddingRight: '12px' },
        },
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main} style={{whiteSpace: 'unset'}}>
            {formatHolidayDate(info.row.original)}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Date' />,
        meta: {
          maxWidth: '220px',
          thStyles: { paddingLeft: '12px' },
          tdStyles: { paddingLeft: '12px' },
        },
      }),
      columnHelper.accessor('country', {
        cell: (info) => {
          const countries = info.row.original.country.split(',');
          return (
            <Box display='flex' gap='6px'>
              {countries.map((country, i) => {
                return (
                  <Chip
                    key={i}
                    style={{
                      backgroundColor: colors.iconStatus.noAction,
                      height: '28px',
                    }}
                    label={
                      <Box display='flex' alignItems='center' gap='4px'>
                        <Icon
                          sx={{
                            svg: {
                              marginTop: '4px',
                              g: { path: { opacity: 1 } },
                            },
                          }}
                        >
                          {country === 'US' ? <USAFlag /> : <UKFlag />}
                        </Icon>{' '}
                        <Typography variant='body' color={colors.text.main}>
                          {country.toUpperCase()}
                        </Typography>
                      </Box>
                    }
                  />
                );
              })}
            </Box>
          );
        },
        header: () => <TableHeaderCell text='Country' />,
        meta: {
          thStyles: { paddingLeft: '12px' },
          tdStyles: { paddingLeft: '12px' },
        },
      }),
      columnHelper.accessor('type', {
        cell: (info) => (
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={colors.textAccent.default}>
                {info.getValue()}
              </Typography>
            }
          />
        ),
        header: () => <TableHeaderCell text='Type' />,
        meta: {
          thStyles: { paddingLeft: '12px', borderRight: 'none' },
          tdStyles: { paddingLeft: '12px', borderRight: 'none' },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <IconButton onClick={() => onRemoveHoliday(row.index)}>
            <ClearIcon />
          </IconButton>
        ),
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '30px',
          maxWidth: '30px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors, formatHolidayDate, onRemoveHoliday]
  );

  return columns;
};
