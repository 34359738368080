import { Row, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FC } from 'react';
import { TableV1 } from '../../../components/Table/TableV1';
import { OpManagerSummaryRowData } from '../../../types';
import { useOpManagerTimesheetsSummaryColumns } from '../hooks/useOpManagerTimesheetsSummaryColumns';

interface Props {
  row: Row<OpManagerSummaryRowData>;
}

export const OPSubTable: FC<Props> = ({ row }) => {
  const columns = useOpManagerTimesheetsSummaryColumns({ nestedTable: true});

  const table = useReactTable({
    data: row.original.ops ?? [],
    columns,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
      <TableV1
        table={table}
        height='auto'
        isLastColumnSticky={false}
        hasHeader={false}
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: 'none'
        }}
      />
  );
};
