import React from 'react';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AllTimesheeetsTable } from './components/AllTimesheeetsTable';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

function DealOwnerAllTimesheets() {
  const isSmallScreen = useAtomValue(isSmallScreenState);

  return (
    <>
      <PageHeader
        title='All Timesheets'
        description=''
        breadcrumbs={[{ title: 'All Timesheets' }]}
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <AllTimesheeetsTable />
      </ContentWrapper>
    </>
  );
}

export default DealOwnerAllTimesheets;
