import { Button, styled, useTheme } from '@mui/material';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { Typography } from '../../../components/Typography/Typography';
import { pendingTsSelectedOPs } from '../../../state/UIDealOwnerState';
import { MonthlyTimesheet, SelectItem } from '../../../types';
import { isSmallScreenState } from '../../../state/UIState';

type Props = {
  columnFilters: ColumnFiltersState;
  table: Table<MonthlyTimesheet>;
};

export function FilterSection({ table, columnFilters }: Props) {
  const { colors } = useTheme();
  const [selectedOPs, setSelectedOPs] = useAtom(pendingTsSelectedOPs);
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const sortedOPList = useMemo(
    () => {
      const allOPs = Array.from(table.getColumn('user')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((user) => ({ id: user.id, value: user.name }));

      const uniqueOPs = Array.from(new Map(allOPs.map((item) => [item.id, item])).values());
      return uniqueOPs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('user')?.setFilterValue(OPs);
      }
    },
    [table, setSelectedOPs]
  );

  const onChangeDateFilter = useCallback(
    (date: Date | null) => {
      //add two hours to date to match the date format
      date?.setHours(date.getHours() + 2);

      table.getColumn('month')?.setFilterValue(date ? date.toISOString() : null);
    },
    [table]
  );

  const isResetDisabled = useMemo(() => {
    const filterValues = columnFilters.map((filter) => filter.value);
    if (filterValues.every((val: any) => !val || val.length === 0)) return true;
    return false;
  }, [columnFilters]);

  const onFiltersReset = useCallback(() => {
    setSelectedOPs([]);
    table.getColumn('month')?.setFilterValue(null);
  }, [table, setSelectedOPs]);

  return (
    <Container>
      <Multiselect
        style={{ width: isSmallScreen ? '150px' : '200px', marginRight: '14px' }}
        fieldPlaceholder={isSmallScreen ? 'OP' : 'Select OP'}
        options={sortedOPList as SelectItem[]}
        onChange={(_, OPs) => onChangeOPFilter(OPs)}
        value={selectedOPs}
      />

      <DatePicker
        clearable
        selectedDate={(table.getColumn('month')?.getFilterValue() as string) ?? null}
        setSelectedDate={onChangeDateFilter}
        disableFuture
        views={['year', 'month']}
        inputFormat='MM/yyyy'
        placeholder={isSmallScreen ? 'Month and Year' : 'Select Month and Year'}
      />
      <Typography variant='caption' color={colors.text.secondary}>
        {`${table.getRowCount()} timesheets`}
      </Typography>
      <Button
        onClick={onFiltersReset}
        variant='text'
        style={{ height: '28px', marginTop: '2px' }}
        disabled={isResetDisabled}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
        }}
      >
        <Typography variant='subtitle1' color={colors.textAccent.default}>
          Reset Filters
        </Typography>
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;
