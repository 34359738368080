import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { OpManagerTimesheetsSummaryTable } from './components/OpManagerTimesheetsSummaryTable';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';
import { Button, useTheme } from '@mui/material';
import { ROUTES } from '../../constants/routes';
import { useIcons } from '../../hooks/useIcons';
import { TimesheetsSummaryNavigation } from './components/OpManagerTimesheetsSummaryNavigation';
import { useCallback, useState } from 'react';
import { useOpManagerTimesheetsSummaryData } from '../../queries/hooks/useOPManagerTimesheetsSummary';
import { timesheetsSummaryExcelTemplate } from './hooks/useTimesheetsSummaryExcelTemplate';

export const OpManagerTimesheetsSummary = () => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear() - 1);
  const { DownLoadIcon } = useIcons();

  const { data, isLoading } = useOpManagerTimesheetsSummaryData(selectedYear);

  const handleSelectedYear = useCallback((year: number) => {
    setSelectedYear(year);
  }, [])

  return (
    <>
      <PageHeader
        title='Timesheets Summary'
        description=''
        breadcrumbs={[
          {
            title: 'Timesheets Summary',
            url: `/${ROUTES.OP_MANAGER_TIMESHEETS_SUMMARY}`,
            color: colors.text.caption,
          },
        ]}
        actionComponent={
          <>
            <TimesheetsSummaryNavigation selectedYear={selectedYear} handleSelectedYear={handleSelectedYear} />
            <Button
              disabled={!data || isLoading}
              variant='outlined'
              startIcon={<DownLoadIcon />}
              style={{
                backgroundColor: colors.surfaceBackground.bg1,
                marginBottom: isSmallScreen ? '10px' : '0px',
                marginTop: isSmallScreen ? '8px' : 0,
              }}
              onClick={() => timesheetsSummaryExcelTemplate(data ?? [], selectedYear)}
            >
              Export
            </Button>
          </>
        }
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <OpManagerTimesheetsSummaryTable selectedYear={selectedYear} />
      </ContentWrapper>
    </>
  );
};
