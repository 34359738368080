import { useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../constants';
import { Http } from '../../services/Http';
import { OpManagerSummaryRowData } from '../../types';

async function fetchOpManagerTimesheetsSummary(year: number): Promise<OpManagerSummaryRowData[]> {
  const { data } = await Http.axios.get<OpManagerSummaryRowData[]>(
    `/monthly-timesheet/op-manager-summary?year=${year}`
  );
  return data;
}

export const useOpManagerTimesheetsSummaryData = (year: number) => {
  return useQuery(
    [QUERY_KEYS.OP_MANAGER_TIMESHEETS_SUMMARY, year],
    () => fetchOpManagerTimesheetsSummary(year),
    {
      staleTime: Infinity,
      enabled: !!year,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useInvalidateOpManagerTimesheetsSummaryData = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.OP_MANAGER_TIMESHEETS_SUMMARY,
    });
  };
};