import { useTheme } from '@mui/material';
import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableV1 } from '../../../components/Table/TableV1';
import { useAccountantSingleTableColumns } from '../hooks/useAccountantSingleTableColumns';
import { MonthlyTimesheetRow, WeeklyTimesheet } from '../../../types';
import { useWeekdayTableData } from '../hooks/useWeekdayTableData';

interface AccountantSingleTimesheetWeekdayTableProps {
  weeklyTimesheet: WeeklyTimesheet[];
  timesheetRows: MonthlyTimesheetRow[];
}

export function AccountantSingleTimesheetWeekdayTable({
  weeklyTimesheet,
  timesheetRows,
}: AccountantSingleTimesheetWeekdayTableProps) {
  const { colors } = useTheme();

  const columns = useAccountantSingleTableColumns();
  const tableData = useWeekdayTableData(weeklyTimesheet, timesheetRows);

  const weekdayTable = useReactTable({
    data: tableData ?? [],
    columns,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  return (
    <TableV1
      hasFooter
      table={weekdayTable}
      height='auto'
      wrapperStyle={{
        maxHeight: 'calc(100vh - 288px)',
        overflowX: 'scroll',
        border: `1px solid ${colors.border.default}`,
        overflowY: 'scroll',
      }}
    />
  );
}
