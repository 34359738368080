import { Button, styled, useTheme } from '@mui/material';
import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as ImportIcon } from '../../../assets/icons/import-blue.svg';
import { UploadFileArea } from '../../../components/UploadFileArea/UploadFileArea';
import { downloadTemplate } from '../hooks/useHolidayExcel';
import { useState } from 'react';
import * as XLSX from 'exceljs';
import { UploadedHolidaysSection } from './UploadedHolidays';
import { useBulkAddHolidays } from '../../../queries/hooks/useAddBulkHoliday';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { Holiday, HolidayExcelRow } from '../../../types';
import { validateHolidayExcelData } from '../../../utils/validateHolidayExcelData';

interface ImportHolidaysModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export function ImportHolidaysModal({ isOpen, handleClose }: ImportHolidaysModalProps) {
  const { colors } = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const [holidayExcelData, setHolidayExcelData] = useState<HolidayExcelRow[]>([]);
  const [excelErrors, setExcelErrors] = useState(false);
  const { mutate: onAddBulkHolidays, isLoading } = useBulkAddHolidays();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();

  const handleFileUpload = async (files: File[]) => {
    if (files.length === 0) return;

    const file = files[0];
    setFile(file);
    const reader = new FileReader();

    reader.onload = async (e) => {
      const buffer = e.target?.result as ArrayBuffer;
      const workbook = new XLSX.Workbook();
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows: any[] = [];

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber <= 4) return; // Skip notes and header

        rows.push({
          name: row.getCell(1).value,
          country: row.getCell(2).value,
          type: row.getCell(3).value,
          startDate: row.getCell(4).value,
          endDate: row.getCell(5).value,
        });
      });

      const { filteredData, hasErrors } = validateHolidayExcelData(rows);

      setHolidayExcelData(filteredData);
      setExcelErrors(hasErrors);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleClearFile = () => {
    setFile(null);
    setHolidayExcelData([]);
  };

  const handleRemoveHoliday = (index: number) => {
    setHolidayExcelData((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    const payload = holidayExcelData.map((holiday) => ({
      ...holiday,
      startDate: holiday.startDate.toISOString().split('T')[0],
      endDate: holiday.endDate ? holiday.endDate.toISOString().split('T')[0] : '',
    }));
    onAddBulkHolidays(payload, {
      onSuccess: (holidays: Holiday[]) => {
        pushSuccessToast({
          title: 'Events successfully imported',
          message: `${holidays.length} ${holidays.length === 1 ? 'was imported' : 'were imported'}`,
        });
        handleClose();
      },
      onError: () => {
        pushErrorToast({ message: `Failed to import events. Please try again` });
      },
    });
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleClose}
      actionButtonsProps={{
        onSubmit: handleSubmit,
        isSubmitDisabled: !holidayExcelData.length || isLoading,
        onCancel: handleClose,
        submitText: 'Import',
      }}
      title='Import Events'
      top='80px'
      width='755px'
    >
      <InfoWrapper>
        <Typography variant='h5' color={colors.text.main}>
          Upload Excel file to import the events
        </Typography>
        <TemplateWrapper>
          <Typography variant='subtitle1' color={colors.text.main}>
            Use Template Events Excel file
          </Typography>
          <Button
            variant='outlined'
            startIcon={<ImportIcon />}
            onClick={downloadTemplate}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            Download
          </Button>
        </TemplateWrapper>
        {file ? (
          <UploadedHolidaysSection
            file={file}
            holidays={holidayExcelData}
            onReuploadFile={handleFileUpload}
            onClearFile={handleClearFile}
            onRemoveHoliday={handleRemoveHoliday}
            hasErrors={excelErrors}
          />
        ) : (
          <UploadFileArea onDrop={handleFileUpload} />
        )}
      </InfoWrapper>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 14px;
`;

const TemplateWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg4};
  border-radius: 8px;
  padding: 8px;
`;
