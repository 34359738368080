import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { LoadingId, Timesheet } from '../../../types';
import { styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { Typography } from '../../../components/Typography/Typography';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useSubmitTimesheet } from '../../../queries/hooks/useSubmitTimesheet';
import { addDaysToDate, formatTSWeekFormat, formatWeekName } from '../../../utils/formatters';
import { useMemo } from 'react';

type Props = {
  timesheet?: Timesheet;
  toggleSubmitTimesheet: () => void;
  submitTsOpen: boolean;
};

export function SubmitTimesheetModal({ timesheet, submitTsOpen, toggleSubmitTimesheet }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const weekName = useMemo(() => {
    if (timesheet?.weekDates?.length) {
      return formatTSWeekFormat(timesheet?.weekDates[0], timesheet?.weekDates[timesheet?.weekDates.length - 1]);
    }
    if (timesheet?.holidays?.length) {
      return formatTSWeekFormat(
        `${timesheet?.holidays[0].year}-${timesheet?.holidays[0].month}-${timesheet?.holidays[0].day}`,
        `${timesheet?.holidays[timesheet?.holidays.length - 1].year}-${timesheet?.holidays[timesheet?.holidays.length - 1].month}-${
          timesheet?.holidays[timesheet?.holidays.length - 1].day
        }`
      );
    }
    return '';
  }, [timesheet?.weekDates]);

  const { mutate: onSubmitTimesheet, isLoading: saveInProgress } = useSubmitTimesheet();

  const handleSubmitTimesheet = async () => {
    startLoading(LoadingId.deleteDeal);
    onSubmitTimesheet(
      {
        id: timesheet?.id,
      },
      {
        onSuccess: () => {
          navigate(-1);
          pushSuccessToast({
            title: 'Timesheet Submitted',
            message: `${weekName} has been submitted for accountant's approval.`,
          });
          toggleSubmitTimesheet();
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to submit timesheet' });
        },
        onSettled: () => {
          stopLoading(LoadingId.deleteDeal);
        },
      }
    );
  };

  return (
    <ModalComponent
      isOpen={submitTsOpen}
      onClose={toggleSubmitTimesheet}
      actionButtonsProps={{
        isSubmitDisabled: saveInProgress,
        onSubmit: handleSubmitTimesheet,
        onCancel: toggleSubmitTimesheet,
        submitText: 'Submit Timesheet',
        submitBtnStyle: {
          //   backgroundColor: deleteInProgress ? undefined : colors.iconStatus.error,
        },
      }}
      title={`Submit Timesheet`}
      top='80px'
    >
      <InfoWrapper>
        <Typography variant='body' color={colors.text.main}>
          {`Are you sure you want to submit ${weekName} timesheet?
The Timesheet will be sent for Accountant's approval.`}
        </Typography>
      </InfoWrapper>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;
